import { memo, useRef } from 'react';
import { Box } from './Box';
import { Chip } from './chip';
import {
  ColumnDirective,
  ColumnsDirective,
  TreeGridComponent,
  Inject,
  Sort,
  Filter,
  Resize,
} from '@syncfusion/ej2-react-treegrid';
import { SearchField } from './SearchField';
import { useItemPropertiesMenuItem } from '../hooks/useItemPropertiesMenuItem';
import { BusinessTypePropertyValues, ItemTypeProperties } from '../types';
import { FilterDropdown } from './FilterDropdown';
import { PropertyValueTemplate } from './item/template/PropertyValueTemplate';
import { Typography, useTheme } from '@mui/material';
import { palette } from '../themes';
import { Tooltip } from './Tooltip';
import { useTooltip } from '../hooks';
import { useSearch } from '../hooks/useSearch';
import { Highlight20Regular } from '@fluentui/react-icons';

export type ItemPropertiesPanelProps = {
  selectedTab?: string;
  itemTypeName?: string;
  businessTypeName?: string;
  propertyDetails: ItemTypeProperties | undefined;
  businessTypeTreeGridData: BusinessTypePropertyValues[];
  rowSelected?;
  propLinks?;
};

export const ItemPropertiesPanel = ({
  selectedTab,
  itemTypeName,
  propertyDetails,
  businessTypeName,
  businessTypeTreeGridData,
  rowSelected,
  propLinks,
}: ItemPropertiesPanelProps) => {
  const theme = useTheme();
  const item = propertyDetails?.value?.[0];
  const menuOptions = [businessTypeName, itemTypeName, 'All Properties'];
  const propLinksData = propLinks?.ResultPropLinks;

  const gridRef = useRef(null);

  // this is for gettng the dropdown menu items
  const { onChange, menuItem, treeGridData } = useItemPropertiesMenuItem({
    item,
    businessTypeTreeGridData,
    businessTypeName,
    itemTypeName,
    gridRef,
    selectedTab,
    propLinksData,
  });

  // create a custom search function for the tree grid.
  const { handleSearch } = useSearch({
    treeGridData,
    gridRef,
    menuItem,
  });

  const handleRowSelection = (rowData: string) => {
    if (rowData) {
      // send rowData string to another component
      rowSelected(rowData);
    }
  };

  return (
    <Box
      padding='large'
      background='none'
      style={{
        paddingLeft: '1.75rem',
        paddingTop: '1.188rem',
      }}
    >
      <Box
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        background='none'
        style={{ marginBottom: '1.188rem', paddingTop: '0.625rem' }}
      >
        <SearchField
          width='17.188rem'
          height='3rem'
          onHandleSearch={handleSearch}
        />
        <FilterDropdown
          value={menuItem}
          onChange={onChange}
          menuOptions={menuOptions}
        />
      </Box>
      <Box background='none'>
        <TreeGridComponent
          dataSource={treeGridData}
          ref={gridRef}
          treeColumnIndex={0}
          allowSorting={true}
          width='100%'
          childMapping='Items'
          id='item-details-properties-grid'
          className='item-details-properties-grid'
          enableCollapseAll
          allowResizing={true}
          enablePersistence={true}
          rowSelected={handleRowSelection}
        >
          <ColumnsDirective>
            <ColumnDirective
              field='propertyName'
              headerText='PROPERTY NAME'
              width='18.5rem'
              template={(item) => {
                const propertyName = item?.propertyName;
                const { dataRef, title } = useTooltip(propertyName);

                return (
                  <Tooltip title={title} arrow>
                    <Typography
                      ref={dataRef}
                      variant='body1'
                      sx={{
                        color: palette.navy[900],
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'break-spaces',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        display: '-webkit-box',
                        wordBreak: 'break-all',
                      }}
                    >
                      {propertyName}
                    </Typography>
                  </Tooltip>
                );
              }}
            />
            <ColumnDirective
              field='value'
              headerText='VALUE'
              width='auto'
              template={(item) => (
                <PropertyValueTemplate itemValue={item.value} />
              )}
            />
            {menuItem !== itemTypeName && (
              <ColumnDirective
                field='highlights'
                headerText='HIGHLIGHTS'
                width='auto'
                template={(item) => (
                  item.highlights ? (
                    <Chip
                      border={false}
                      title={item.highlights}
                      icon={<Highlight20Regular />}
                      color='success'
                      sx={{
                        backgroundColor: palette.aqua[50],
                        color: palette.aqua[900]
                      }}
                    />
                  ) : null
                )}
              />
            )}

          </ColumnsDirective>
          <Inject services={[Sort, Filter, Resize]} />
        </TreeGridComponent>
      </Box>
    </Box>
  );
};

export const MemoizedItemPropertiesPanel = memo(
  ItemPropertiesPanel,
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.businessTypeTreeGridData) ===
      JSON.stringify(nextProps.businessTypeTreeGridData)
    );
  }
);
