import { Button, Divider, styled, useTheme } from '@mui/material';
import { useDownloadFile } from '../hooks';
import { Box } from './Box';
import { FileViewer } from './FileViewer';
import { MemoizedItemPropertiesPanel } from './ItemPropertiesPanel';
import { ItemSummaryPanel } from './ItemSummaryPanel';
import { ItemSummaryTitle } from './ItemSummaryTitle';
import { TabPanel } from './TabPanel';

import { useItemPanel } from '../hooks';
import { DisposalDetailsPanel } from './DisposalDetailsPanel';
import { DisposalRequest, ItemGridQuery } from '../types';
import { insertIf } from '../util/array-utils';
import { ColumnEdit20Regular } from '@fluentui/react-icons';
import { ItemsGrid } from './grid';
import { useState } from 'react';
import { Dispatch, useMemo } from 'react';
import { memo } from 'react';

const StyledImage = styled('img')``;

export type ItemPanelProps = {
  id?: string;
  initialTab?: string;
  onSelectTab?: (tab: string) => void;
  adminMode?: boolean;
  disposalRequest?: DisposalRequest;
  setId?: Dispatch<any>;
};

export interface IHighlightsData {
  text: string;
  start: number;
  end: number;
}

/**
 * ItemPanel component to display details of an item
 * @param id - id of the Item
 * @param tab - selected tab ('summary' etc.,)
 * @event onSelectTab - select tab event
 * @returns ItemPanel JSX.Element
 */
export const ItemPanel = memo(
  ({
    id,
    initialTab,
    onSelectTab,
    adminMode,
    disposalRequest,
    setId,
  }: ItemPanelProps) => {
    // -----------------------------
    // Theme and utility hooks
    // -----------------------------
    const theme = useTheme();

    const {
      iconDetails,
      itemDetails,
      selectedTab,
      handleSelectTab,
      propertyDetails,
      treeGridProperties,
      businessTypeProperties,
      businessTypeIconDetails,
      businessTypeTreeGridData,
      businessTypePropertyValues,
      itemCollectionCount,
      showColumnChooser,
      handleCheckbox,
      handleOpen,
      handleOpenURL,
      gridRef,
      selectedCheckbox,
      fullScreenTab,
      handleOpenItems,
      levels,
      hasPreviewableContent,
      setPreviewState,
      propLinksData,
    } = useItemPanel({ id, initialTab, onSelectTab, disposalRequest, setId });

    const [selectedRowHighlight, setSelectedRowHighlight] = useState<
      Array<IHighlightsData>
    >([]);

    const { downloadFile } = useDownloadFile();

    const rowSelected = (rowData) => {
      if (rowData?.data?.highlightData?.length > 0) {
        setSelectedRowHighlight(rowData.data.highlightData);
      }
    };

    const Grid = useMemo(() => {
      return (
        <ItemsGrid
          onOpen={handleOpen}
          onOpenURL={handleOpenURL}
          onOpenItems={handleOpenItems}
          onSelect={handleCheckbox}
          disposalId={id!}
          persistanceId={`panelGrid${JSON.stringify(levels).length}-4`}
          ref={gridRef}
          itemGridType={ItemGridQuery.Child}
          selectedRecords={selectedCheckbox}
        />
      );
    }, [id, levels]);

    return (
      <Box background='none'>
        <ItemSummaryTitle
          isMoreButtonAvailable={hasPreviewableContent}
          icon={
            iconDetails ? (
              <StyledImage
                src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
                alt={iconDetails?.AltText}
                style={{ height: '1.5rem' }}
              />
            ) : null
          }
          displayName={itemDetails?.DisplayName}
          itemType={itemDetails?.TypeDef?._Display}
          businessType={itemDetails?.BusinessType?._Display}
          onDownloadClick={() => {
            downloadFile({
              id,
              itemDetails,
            });
          }}
        />
        <Box direction='row' background='none'>
          <Box background='none' width={!fullScreenTab ? '50%' : '100%'}>
            <TabPanel
              selected={selectedTab}
              onSelect={handleSelectTab}
              inset='3.5rem'
              tabs={[
                {
                  title: 'Summary',
                  value: 'summary',
                  children: (
                    <ItemSummaryPanel
                      item={itemDetails}
                      adminMode={adminMode}
                      treeGridProperties={treeGridProperties}
                      businessTypeProperties={businessTypeProperties}
                      businessTypeIconDetails={businessTypeIconDetails}
                      businessTypePropertyValues={businessTypePropertyValues}
                      onOpen={handleOpen}
                    />
                  ),
                },
                {
                  title: 'Properties',
                  value: 'properties',
                  children: (
                    <MemoizedItemPropertiesPanel
                      selectedTab={selectedTab}
                      propertyDetails={propertyDetails}
                      businessTypeTreeGridData={businessTypeTreeGridData}
                      rowSelected={rowSelected}
                      itemTypeName={
                        itemDetails?.TypeDef?._Display
                          ? `${itemDetails?.TypeDef?._Display} Properties`
                          : undefined
                      }
                      businessTypeName={
                        itemDetails?.BusinessType?._Display
                          ? `${itemDetails?.BusinessType?._Display} Properties`
                          : undefined
                      }
                      propLinks={propLinksData}
                    />
                  ),
                },
                {
                  title: 'Disposal Details',
                  value: 'disposalDetails',
                  children: !!disposalRequest && (
                    <DisposalDetailsPanel
                      request={disposalRequest}
                      itemId={itemDetails?.ID}
                    />
                  ),
                },
                ...insertIf(
                  itemDetails?.AttachedItems?.value.length > 0 ||
                  itemDetails?.TypeDef?._Display == 'Item Collection',
                  {
                    title: `${itemCollectionCount?.count} ${itemDetails.AttachedItems?.value?.length > 0
                      ? 'attachment'
                      : 'item'
                      }${itemCollectionCount?.count != 1 ? `s` : ``}`,
                    value: 'items',
                    children: !!disposalRequest && (
                      <Box
                        padding='large'
                        background='none'
                        style={{
                          paddingTop: '0.6rem',
                          height: '100%',
                        }}
                      >
                        <Box
                          direction='row'
                          alignItems='center'
                          background='none'
                          height={5}
                        >
                          <Box
                            background='none'
                            gap={'small'}
                            direction='row'
                            justifyContent='end'
                          >
                            <Button
                              variant='text'
                              startIcon={<ColumnEdit20Regular />}
                              onClick={showColumnChooser}
                            >
                              Change columns
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          background='none'
                          style={{
                            paddingTop: '0.6rem',
                            overflow: 'scroll',
                            height: '100%',
                          }}
                        >
                          {Grid}
                        </Box>
                      </Box>
                    ),
                  }
                ),
              ]}
            />
          </Box>
          {!fullScreenTab && (
            <Box
              width='50%'
              height='calc(100% - 2rem)'
              alignItems='center'
              justifyContent='center'
              style={{
                backgroundColor: theme.palette.info[100],
                marginTop: '2rem',
              }}
            >
              <Divider style={{ width: '100%' }} />
              <FileViewer
                itemDetails={itemDetails}
                rowData={selectedRowHighlight}
                setPreviewState={setPreviewState}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
