import { useEffect, useState } from 'react';
import {
  Item,
  ItemDisposalStatus,
  RetentionClass,
} from '../../../types/itemTypes';
import { Box } from '../../Box';
import { ItemDisposalStatusChip } from '../../chip/ItemDisposalStatusChip';

export const DisposalStatusTemplate = ({ item }: { item?: Item }) => {
  const [retentionClassLookup, setRetentionClassLookup] = useState<
    Record<string, RetentionClass>
  >({});

  useEffect(() => {
    const _lookup = {};
    item.Classifications?.value?.forEach(
      (rc: RetentionClass) => (_lookup[rc.ID] = rc)
    );
    setRetentionClassLookup(_lookup);
  }, [item.Classifications?.value]);

  return (
    <Box background='none' alignItems='start'>
      {(item.DisposalStates?.value ?? []).map((disposalState, i) => (
        <ItemDisposalStatusChip
          status={disposalState.DisposalStatus as ItemDisposalStatus}
          subTitle={
            retentionClassLookup[disposalState.RetentionClassId ?? 'unknown']
              ?.DisplayName ?? '-'
          }
          key={i}
        />
      ))}
    </Box>
  );
};
