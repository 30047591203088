import { User } from '../types';
import { useMsalUser } from '../auth';
import { useEffect, useState } from 'react';
import { useGetCurrentUserQuery } from '../services';

interface UserWithApiError extends User {
  isUserApiError?: boolean;
  UserAccessType?: string;
}

export function useUser() {
  const msalUser = useMsalUser();
  const [user, setUser] = useState<UserWithApiError>();

  const { data: apiUser, isError } = useGetCurrentUserQuery(undefined, {
    skip: !msalUser?.localId,
  });

  useEffect(() => {
    setUser({ ...msalUser, ...apiUser, isUserApiError: isError });
  }, [msalUser, apiUser, isError]);
  return user;
}
