import { MouseEvent } from 'react';
import { ArrowRight20Regular } from '@fluentui/react-icons';
import { Link, Typography, TypographyProps, useTheme } from '@mui/material';

export const LinkWithArrow = ({
  title,
  onClick,
  onContextMenu,
  variant = 'body1',
}: {
  title: string;
  onClick?: () => void;
  onContextMenu?: (e: MouseEvent<HTMLElement>) => void;
  variant?: TypographyProps['variant'];
}) => {
  const theme = useTheme();
  return (
    <Link
      sx={{
        textDecoration: 'none',
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        '&>#title': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          color:
            variant == 'body2'
              ? theme.palette.info.main
              : `${theme.palette.info.dark} !important`,
        },
        '&>#arrow': { opacity: 0, flexShrink: 0 },
        '&:hover': {
          '&>#title': {
            cursor: 'pointer',
            color: `${theme.palette.primary.main} !important`,
          },
          '&>#arrow': { opacity: 1 },
        },
      }}
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick();
      }}
      onContextMenu={(e: MouseEvent<HTMLElement>) =>
        onContextMenu && onContextMenu(e)
      }
    >
      <Typography id='title' noWrap variant={variant}>
        {title}&nbsp;
      </Typography>
      <ArrowRight20Regular id='arrow' />
    </Link>
  );
};
