import { BusinessTypePropDefsValue } from "../../types";

export enum TypeKind {
    Primitive = 'Primitive',
    Enum = 'Enum',
    Entity = 'Entity',
  }
  
  export enum PrimitiveDataType {
    String = 'String',
    Boolean = 'Boolean',
    Date = 'Date',
    Double = 'Double',
    Int64 = 'Int64',
    Int32 = 'Int32',
  }
  
export const isMultiline = (details: BusinessTypePropDefsValue) =>
  details?.TypeKind === TypeKind.Primitive &&
  details?.PrimitiveDataType === PrimitiveDataType.String &&
  details.IsText;

export const isSingleLine = (details: BusinessTypePropDefsValue) =>
  details?.TypeKind === TypeKind.Primitive &&
  details?.PrimitiveDataType === PrimitiveDataType.String &&
  !details.IsText;

export const isString = (details: BusinessTypePropDefsValue) =>
  details?.TypeKind === TypeKind.Primitive &&
  details?.PrimitiveDataType === PrimitiveDataType.String;

export const isBoolean = (details: BusinessTypePropDefsValue) =>
  details?.TypeKind === TypeKind.Primitive &&
  details?.PrimitiveDataType === PrimitiveDataType.Boolean;

export const isDate = (details: BusinessTypePropDefsValue) =>
  details?.TypeKind === TypeKind.Primitive &&
  details?.PrimitiveDataType === PrimitiveDataType.Date;

export const isEnum = (details: BusinessTypePropDefsValue) =>
  details?.TypeKind === TypeKind.Enum;

export const isDouble = (details: BusinessTypePropDefsValue) =>
  (details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.Double)

export const isInteger = (details: BusinessTypePropDefsValue) =>
  (details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.Int32) ||
  (details?.TypeKind === TypeKind.Primitive &&
    details?.PrimitiveDataType === PrimitiveDataType.Int64);

export const isEntity = (details: BusinessTypePropDefsValue) =>
  details?.TypeKind === TypeKind.Entity;