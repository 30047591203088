import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

type ItemLevel = {
  name: string;
  id: string;
};

type itemModalStateType = {
  navigation: {
    levels: ItemLevel[];
  };
};

export const itemInitialState: itemModalStateType = {
  navigation: {
    levels: [],
  },
} as itemModalStateType;

export const itemModalSlice = createSlice({
  name: 'itemModal',
  initialState: itemInitialState as itemModalStateType,
  reducers: {
    setCurrentItem: (
      state: itemModalStateType,
      action: PayloadAction<ItemLevel>
    ) => {
      if (action.payload == undefined) {
        state.navigation.levels = [];
      } else {
        const foundNavigatedIndex = state.navigation.levels.findIndex(
          (x) => x.id == action.payload.id
        );
        const wasNavigatedTo = foundNavigatedIndex != -1;
        if (wasNavigatedTo) {
          const shrinkBreadcrumb = state.navigation.levels.splice(
            0,
            foundNavigatedIndex + 1
          );
          state.navigation.levels = shrinkBreadcrumb;
        } else {
          const expandBreadcrumb = [...state.navigation.levels, action.payload];
          state.navigation.levels = expandBreadcrumb;
        }
      }
    },
  },
});

export const { setCurrentItem } = itemModalSlice.actions;

export default itemModalSlice.reducer;
