import { styled } from '@mui/material';
import { useState } from 'react';
import { Box } from '../Box';
import { InfoPanel } from '../InfoPanel';
import { TextIconButton } from '../button';
import { DetailsRow } from '../DetailsRow';
import { getFormattedDateTime } from '../../util';
import { Add12Regular } from '@fluentui/react-icons';
import { PerspectiveClassChip } from '../chip/PerspectiveClassChip';
import { Item, ItemBusinessClass, RetentionClass } from '../../types';
import { Chip, ItemManagementStatusChip, RetentionClassChip } from '../chip';
import {
  EditCategoriesDialog,
  EditPerspectiveClassesDialog,
  EditRetentionClassesDialog,
} from '../dialog';
import { useSaveItemDetailsMutation } from '../../services';
import { useUser, useIcon } from '../../hooks';
import { OpenInContextMenuTarget } from '../grid/components/OpenInContextMenu';
import { Typography } from '../Typography';

const StyledImage = styled('img')``;

export type ItemGovernancePanelProps = {
  item: Item;
  adminMode?: boolean;
  onOpen?: (item: Item, target?: OpenInContextMenuTarget) => void;
};

enum Dialog {
  PERSPECTIVE = 'perspective',
  RETENTION = 'retention',
  CATEGORIES = 'categories',
}

type EditDialogProps = {
  open: boolean;
  title?: string;
  dialog?: Dialog;
  fieldLabel?: string;
  propertyName?: string;
  propertyValue?: ItemBusinessClass[] | RetentionClass[] | any[];
};

export const ItemGovernancePanel = ({
  item,
  adminMode,
  onOpen,
}: ItemGovernancePanelProps) => {
  const user = useUser();

  const userPermission = user?.UserAccessType === 'Full' ?? false;
  const hasEditPermission = adminMode && userPermission;

  const [dialogProps, setDialogProps] = useState<EditDialogProps>({
    open: false,
  });

  const [saveItemDetails, { isLoading }] = useSaveItemDetailsMutation();

  // Add and Edit variables for Perspective Classes
  const hasAddButtonPerspective =
    hasEditPermission && !item?.BusinessClasses?.value?.length;
  const hasEditButtonPerspective =
    hasEditPermission && item?.BusinessClasses?.value?.length;

  // Add and Edit variables for Retention Classes
  const hasAddButtonRetention =
    hasEditPermission && !item?.Classifications?.value?.length;
  const hasEditButtonRetention =
    hasEditPermission && item?.Classifications?.value?.length;

  const hasAddButtonCategories =
    hasEditPermission && !item?.Categories?.value?.length;
  const hasEditButtonCategories =
    hasEditPermission && item?.Categories?.value?.length;

  const setDialogDetails = ({
    open,
    title,
    dialog,
    fieldLabel,
    propertyName,
    propertyValue,
  }: EditDialogProps) =>
    setDialogProps({
      open,
      title,
      dialog,
      fieldLabel,
      propertyName,
      propertyValue,
    });

  const handleClose = () => {
    setDialogDetails({
      open: false,
    });
  };

  const getIcon = (id) => {
    if (id) {
      const { iconDetails } = useIcon({
        imageId: id,
      });

      return `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`;
    } else {
      return `${process.env.REACT_APP_ENC_IMC_URL}BusinessClasses/Documents/document header footer.svg`;
    }
  };

  const handleSave = async (value, propertyName) => {
    // this is to prevent from calling the API again.
    if (isLoading) return;

    // Format the selected value(s) from the dialog.
    // Get only the needed IDs (ID and TypeDefId)
    const formattedDialogValues = value?.length
      ? value?.map(({ ID, TypeDefId }) => ({
          ID,
          TypeDefId,
        }))
      : null;

    // Format form details
    // propertyName is based on the selected dialog
    // BusinessClasses property = Perspective Classes Dialog
    // Classifications property = Retention Classes Dialog
    const formDetails = {
      [propertyName]: formattedDialogValues,
    };

    const { ID, TypeDefId } = item;

    // request object
    const requestObj = {
      ID,
      TypeDefId,
      formDetails,
    };

    try {
      const result = await saveItemDetails(requestObj);

      console.log('Item Details updated:', result);
    } catch (error) {
      // Handle error
      console.error('Error on updating the item details:', error);
    }

    setDialogDetails({ open: false });
  };

  return (
    <>
      {Object.keys(item).length ? (
        <>
          <InfoPanel
            title='Governance Properties'
            inset='3.5rem'
            style={{
              paddingRight: '5.25rem',
              paddingTop: '0.988rem',
              paddingBottom: '3.25rem',
            }}
          >
            <DetailsRow
              label='Status'
              data={
                <ItemManagementStatusChip
                  status={item?.Status}
                  border={true}
                  sx={{ minHeight: '2.5rem' }}
                />
              }
              alignData
              key='governance-status'
            />
            <DetailsRow
              label='Date Created in EncompaaS'
              data={getFormattedDateTime(item?.DateCreated)}
              key='governance-date-created-in-encompaas'
            />
            <DetailsRow
              label='Member Of'
              data={item?.MemberOf?.value?.map(
                (memberOf: any, index: number) => (
                  <Chip
                    title={memberOf?._Display}
                    icon={
                      <StyledImage
                        src={getIcon(memberOf._ImageId)}
                        style={{ height: '1.5rem' }}
                      />
                    }
                    isLink={true}
                    onClick={() => onOpen && onOpen(memberOf, 'modal')}
                    sx={{ minHeight: '2.5rem' }}
                  />
                )
              )}
              key='governance-member-of'
            />
            <DetailsRow
              label='Perspective Classes'
              data={
                <Box
                  direction='row'
                  background='none'
                  gap='small'
                  style={{ flexWrap: 'wrap' }}
                >
                  {hasAddButtonPerspective ? (
                    <Box background='none' direction='row'>
                      <TextIconButton
                        size='medium'
                        color='primary'
                        textVariant='body2'
                        startIcon={<Add12Regular />}
                        onClick={() =>
                          setDialogDetails({
                            open: true,
                            dialog: Dialog.PERSPECTIVE,
                            propertyName: 'BusinessClasses',
                            title: 'Add Perspective Classes',
                            fieldLabel: 'Perspective Classes',
                            propertyValue: item?.BusinessClasses?.value,
                          })
                        }
                        text='Add perspective classes'
                      />
                    </Box>
                  ) : (
                    item?.BusinessClasses?.value?.map(
                      (businessClass: ItemBusinessClass, index: number) => (
                        <PerspectiveClassChip
                          businessClass={businessClass}
                          key={`${businessClass?._Display}-${index}`}
                        />
                      )
                    )
                  )}
                </Box>
              }
              alignData
              key='governance-perspective-classes'
              onEdit={
                hasEditButtonPerspective
                  ? () =>
                      setDialogDetails({
                        open: true,
                        dialog: Dialog.PERSPECTIVE,
                        propertyName: 'BusinessClasses',
                        title: 'Edit Perspective Classes',
                        fieldLabel: 'Perspective Classes',
                        propertyValue: item?.BusinessClasses?.value,
                      })
                  : null
              }
            />
            <DetailsRow
              label='Categories'
              data={
                <Box
                  direction='column'
                  background='none'
                  gap='small'
                  style={{ flexWrap: 'wrap' }}
                >
                  {hasAddButtonCategories ? (
                    <Box background='none' direction='row'>
                      <TextIconButton
                        size='medium'
                        color='primary'
                        textVariant='body2'
                        startIcon={<Add12Regular />}
                        onClick={() =>
                          setDialogDetails({
                            open: true,
                            dialog: Dialog.CATEGORIES,
                            propertyName: 'Categories',
                            title: 'Add Categories',
                            fieldLabel: 'Categories',
                            propertyValue: item?.Categories?.value,
                          })
                        }
                        text='Add categories'
                      />
                    </Box>
                  ) : (
                    item?.Categories?.value?.map((category, index: number) => (
                      <Typography
                        variant='body2'
                        key={`${category?.DisplayName}-${index}`}
                      >
                        {category?.DisplayName ?? category?._Display}
                      </Typography>
                    ))
                  )}
                </Box>
              }
              alignData
              key='governance-categories'
              onEdit={
                hasEditButtonCategories
                  ? () =>
                      setDialogDetails({
                        open: true,
                        dialog: Dialog.CATEGORIES,
                        propertyName: 'Categories',
                        title: 'Edit Categories',
                        fieldLabel: 'Categories',
                        propertyValue: item?.Categories?.value,
                      })
                  : null
              }
            />
            <DetailsRow
              label='Retention Classes (all)'
              key='governance-rentention-classes'
              data={
                <Box
                  direction='row'
                  background='none'
                  gap='small'
                  style={{
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    maxWidth: '100%',
                  }}
                >
                  {hasAddButtonRetention ? (
                    <Box background='none' direction='row' width='100%'>
                      <TextIconButton
                        size='medium'
                        color='primary'
                        textVariant='body2'
                        startIcon={<Add12Regular />}
                        onClick={() =>
                          setDialogDetails({
                            open: true,
                            dialog: Dialog.RETENTION,
                            title: 'Add Retention Classes',
                            fieldLabel: 'Retention Classes',
                            propertyName: 'Classifications',
                            propertyValue: item?.Classifications?.value,
                          })
                        }
                        text='Add retention classes'
                      />
                    </Box>
                  ) : null}
                  {item?.ApplicableClassifications?.value?.map(
                    (classification: RetentionClass, index: number) => (
                      <RetentionClassChip
                        classification={classification}
                        key={`${classification?.DisplayName}-${index}`}
                        sx={{ maxWidth: '100%' }}
                      />
                    )
                  )}
                </Box>
              }
              alignData
              onEdit={
                hasEditButtonRetention
                  ? () =>
                      setDialogDetails({
                        open: true,
                        dialog: Dialog.RETENTION,
                        title: 'Edit Retention Classes',
                        fieldLabel: 'Retention Classes',
                        propertyName: 'Classifications',
                        propertyValue: item?.Classifications?.value,
                      })
                  : null
              }
            />
          </InfoPanel>
          {dialogProps.dialog === Dialog.PERSPECTIVE && (
            <EditPerspectiveClassesDialog
              onSave={handleSave}
              onClose={handleClose}
              open={dialogProps.open}
              title={dialogProps.title}
              fieldLabel={dialogProps.fieldLabel}
              propertyName={dialogProps.propertyName}
              propertyValue={dialogProps.propertyValue as ItemBusinessClass[]}
            />
          )}
          {dialogProps.dialog === Dialog.RETENTION && (
            <EditRetentionClassesDialog
              onSave={handleSave}
              onClose={handleClose}
              open={dialogProps.open}
              title={dialogProps.title}
              fieldLabel={dialogProps.fieldLabel}
              propertyName={dialogProps.propertyName}
              propertyValue={dialogProps.propertyValue as RetentionClass[]}
            />
          )}
          {dialogProps.dialog === Dialog.CATEGORIES && (
            <EditCategoriesDialog
              onSave={handleSave}
              onClose={handleClose}
              open={dialogProps.open}
              title={dialogProps.title}
              fieldLabel={dialogProps.fieldLabel}
              propertyName={dialogProps.propertyName}
              propertyValue={dialogProps.propertyValue as any}
            />
          )}
        </>
      ) : null}
    </>
  );
};
