import { memo } from 'react';
import { Box } from './Box';
import { List, TextField, Typography, useTheme } from '@mui/material';
import { FixedSizeList } from 'react-window';

import {
  ChevronDown20Regular,
  ChevronUp20Regular,
} from '@fluentui/react-icons';
import { Button } from './button';
import React from 'react';
import { OptionSelectItem } from './OptionSelectItem';

import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { SortableOptionList, Type } from './SortableOptionList';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CollapsibleGroupedList } from './CollapsibleGroupedList';
import { useOptionMultiSelect } from '../hooks/useOptionMultiSelect';

// Define generic types for the component props
interface OptionSelectProps<T> {
  type?: Type;
  options: T[];
  groupBy?: string;
  hasIcon?: boolean;
  required?: boolean;
  placeholder: string;
  propertyValue?: T[];
  multiSelect?: boolean;
  selectedOptions?: T[];
  itemIcon?: React.ReactNode;
  handleSearch?: (searchTerm: string, searchResult?: T[]) => void;
  getSelectedOptions?: (selectedOptions?: T[]) => void;
}

export const OptionMultiSelect = memo(
  <
    T extends {
      ID?: string;
      _Display?: string;
      _ImageId?: string;
    }
  >({
    groupBy,
    options,
    itemIcon,
    required,
    placeholder,
    handleSearch,
    propertyValue,
    hasIcon = true,
    getSelectedOptions,
    type = Type.DEFAULT,
  }: OptionSelectProps<T>) => {
    const theme = useTheme();

    const {
      sensors,
      onSearch,
      optionList,
      showOptionList,
      selectedOptions,
      isValidOptionList,
      handleDragEnd,
      handleOnSelect,
      handleOnDelete,
      setShowOptionList,
    } = useOptionMultiSelect({
      options,
      propertyValue,
      handleSearch,
      getSelectedOptions,
    });

    const borderColor =
      !isValidOptionList && required
        ? theme.palette.warning.main
        : theme.palette.info[600];

    const renderDropdownList = () => {
      if (!optionList?.length) {
        return (
          <Typography variant='body2' ml={2} mb={2}>
            No records to display
          </Typography>
        );
      }

      if (groupBy) {
        return (
          <CollapsibleGroupedList
            groupBy={groupBy}
            optionList={optionList}
            handleSelect={handleOnSelect}
          />
        );
      }

      return (
        <AutoSizer style={{ height: 'auto', paddingBottom: '0.5rem' }}>
          {() => (
            <FixedSizeList
              height={208}
              itemCount={optionList.length}
              itemSize={48}
              width={473}
            >
              {({ index, style }) => {
                const option = optionList[index];
                const key = `${option?.ID}-${index}`;

                return (
                  <div style={style}>
                    <OptionSelectItem
                      key={key}
                      type={type}
                      option={option}
                      hasIcon={hasIcon}
                      itemIcon={itemIcon}
                      handleSelect={handleOnSelect}
                    />
                  </div>
                );
              }}
            </FixedSizeList>
          )}
        </AutoSizer>
      );
    };

    return (
      <Box
        width='29.563rem'
        background='none'
        style={{ marginBottom: '0.75rem', marginTop: '0.5rem' }}
      >
        {selectedOptions?.length ? (
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <List
              dense
              sx={{
                padding: 0,
                overflow: 'auto',
                maxHeight: '12rem',
                borderRadius: '0.5rem',
                marginBottom: '0.75rem',
                width: 'calc(100% - 0.125rem)',
                border: `1px solid ${theme.palette.background.paper}`,
              }}
            >
              <SortableContext items={selectedOptions?.map((item) => item.ID)}>
                {selectedOptions?.map((option, index) => (
                  <SortableOptionList
                    type={type}
                    index={index}
                    option={option}
                    displayIndex={false}
                    key={`sortable_${option.ID}`}
                    onDelete={() => handleOnDelete(index, option)}
                  />
                ))}
              </SortableContext>
            </List>
          </DndContext>
        ) : null}
        <Box background='none'>
          <Button
            color='info'
            variant='outlined'
            sx={{
              borderColor,
              width: '100%',
              height: '3rem',
              fontFamily: 'Avenir Roman',
              justifyContent: 'space-between',
            }}
            endIcon={
              <Box background='none' direction='row' alignItems='center'>
                {showOptionList ? (
                  <ChevronUp20Regular color={theme.palette.primary.main} />
                ) : (
                  <ChevronDown20Regular color={theme.palette.primary.main} />
                )}
              </Box>
            }
            onClick={() => {
              setShowOptionList((prevState) => !prevState);
            }}
          >
            {placeholder}
          </Button>
          <Box
            background='none'
            style={{
              height: 'auto',
              maxHeight: '17rem',
              width: '29.563rem',
              position: 'absolute',
              background: 'white',
              marginTop: '3.313rem',
              borderRadius: '0.5rem',
              display: showOptionList ? 'inherit' : 'none',
              boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
              border: `0.063rem solid ${theme.palette.info[600]}`,
            }}
            direction='column'
          >
            <TextField
              size='small'
              sx={{
                height: '2.5rem',
                minHeight: '2.5rem',
                margin: '0.5rem 0.75rem',
                width: 'calc(100% - 1.55rem)',
              }}
              placeholder='Search'
              onChange={(event) => onSearch(event)}
            />
            {renderDropdownList()}
          </Box>
        </Box>
      </Box>
    );
  }
);
