import { TooltipProps, Tooltip as MUITooltip } from '@mui/material';

export const Tooltip = ({ title, children, ...props }: TooltipProps) => (
  <MUITooltip
    title={
      typeof title === 'string' && title ? (
        <span className='tooltip-title'>{title}</span>
      ) : (
        title
      )
    }
    {...props}
  >
    {children}
  </MUITooltip>
);
