import {
  useGetDisposalRequestActioningRequiredQuery,
  useGetDisposalRequestRejectedInOtherRequestQuery,
  useGetItemExceptionDisposalDetailsQuery
} from '../services';
import { DisposalDetailsCollection } from '../types';

interface UseDisposalRequestParams {
  id?: string;
  userApiError?: boolean;
}

export const useDisposalDetailsExceptions = ({
  id,
  userApiError = false,
}: UseDisposalRequestParams) => {
  const {
    data: actioningData,
    isSuccess: actioningIsSuccess,
    isError: actioningIsError,
    error: actioningError,
  } = useGetDisposalRequestActioningRequiredQuery(
    {
      requestId: id!,
    },
    { skip: !id }
  );

  const {
    data: rejectedData,
    isSuccess: rejectedIsSuccess,
    isError: rejectedIsError,
    error: rejectedError,
  } = useGetDisposalRequestRejectedInOtherRequestQuery(
    {
      requestId: id!,
    },
    { skip: !id }
  );

  const error = [rejectedError, actioningError];
  const isSuccess = actioningIsSuccess && rejectedIsSuccess;
  const isError = actioningIsError && rejectedIsError;

  if (isError && (rejectedError || actioningError)) {
    console.error(error);
  }

  const transformedData = userApiError
    ? undefined
    : {
        ActioningRequired:
          ((actioningData && actioningData['@odata.count']) ?? 0) > 0,
        RejectedInOtherRequest:
          ((rejectedData && rejectedData['@odata.count']) ?? 0) > 0,
      };

  return { data: transformedData, isSuccess, isError };
};
export const useItemDisposalDetailsExceptions = ({
  id,
  userApiError = false,
}: UseDisposalRequestParams) => {
  const {
    data,
    isSuccess,
    isError,
    error
  } = useGetItemExceptionDisposalDetailsQuery(
    {
      itemId: id!,
    },
    { skip: !id }
  );

  if (isError && error) {
    console.error(error);
  }

  const value  = (data as unknown as DisposalDetailsCollection)?.value ?? null
  const transformedData = userApiError
    ? undefined
    : value === null ? null : value[0];

  return { data: transformedData, isSuccess, isError };
};
