import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Box } from '../Box';
import { Button } from '../button';
import { Edit20Regular } from '@fluentui/react-icons';
import { CustomTypeField } from './components/item/CustomTypeField';
import {
  BusinessTypePropDefsValue,
  ItemBusinessObject,
  TreeGridProperties,
} from '../../types';
import { useGetBusinessTypePropertiesPanelValuesQuery } from '../../services';
import { useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { isEntity } from '../item/utils';

export type Items = {
  id: string;
  value: any;
  Items?: Items[];
  important: string;
  propertyName: string;
  fieldDetails?: { [key: string]: unknown };
};

export type FormValue = {
  ID?: string;
  TypeDefId?: string;
  [key: string]: any;
  '@enweb.Save'?: boolean;
};

export type EditEntityDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  itemId?: string;
  typeDefId?: string;
  isLoading?: boolean;
  formValue: FormValue;
  onClose?: () => void;
  propertyName?: string;
  businessObject: ItemBusinessObject;
  treeGridProperties: TreeGridProperties;
  propertyDetails: BusinessTypePropDefsValue;
  onSave?: (value: FormValue, propertyName?: string) => void;
};

export type OnChangeValue = {
  data?: any;
  propertyName?: string;
};

export const EditCustomTypeDialog = ({
  open,
  title,
  itemId,
  onSave,
  onClose,
  typeDefId,
  isLoading = false,
  propertyName,
  formValue,
  businessObject,
  propertyDetails,
  treeGridProperties,
}: EditEntityDialogProps) => {
  const [value, setValue] = useState(formValue);

  const onChange = (args: OnChangeValue) => {
    const _data = args?.data;

    // function to format the tree grid data
    const formatTreeGridData = (data) => {
      const formattedData = {};

      data.forEach((item) => {
        const { fieldDetails, value } = item;

        const isPerspectiveClassType =
          isEntity(fieldDetails) &&
          fieldDetails?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
          fieldDetails?.PropTypeDef?.Category?.InternalName === 'Inheritance';

        // check if there's a field details and if the field is not a collection
        if (fieldDetails && !fieldDetails?.IsCollection) {
          if (!formattedData?.['ID'] || !formattedData['TypeDefId']) {
            formattedData['ID'] = formValue?.ID ?? null;
            formattedData['TypeDefId'] =
              formValue?.TypeDefId ?? fieldDetails?.TreeDataTypeDefId;
          }

          formattedData[fieldDetails?.Name] = value ?? null;

          // Format the value if Perspective Class
          if (isPerspectiveClassType && value) {
            formattedData[fieldDetails?.Name] = {
              ID: value?.ID,
              TypeDefId: value?.TypeDefId,
              '@enweb.Save': true,
            };

            formattedData[`${fieldDetails?.Name}Id`] = value?.ID;
          }
        }
        // if the item is a collection
        else {
          formattedData[fieldDetails?.Name] = !isEmpty(
            formattedData?.[fieldDetails?.Name] ?? []
          )
            ? [...formattedData[fieldDetails?.Name]]
            : [];

          const getSubItems = (items) => {
            const _subItems = {};

            items.forEach((_item) => {
              const { fieldDetails: _fieldDetails, value: _value } = _item;

              const isPerspectiveClassType =
                isEntity(_fieldDetails) &&
                _fieldDetails?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
                _fieldDetails?.PropTypeDef?.Category?.InternalName ===
                  'Inheritance';

              // check if there's a field details and if the field is not a collection
              if (
                _fieldDetails &&
                !_fieldDetails?.IsCollection &&
                !_item.Items.length
              ) {
                // Set Child Item ID
                if (!_subItems?.['ID'])
                  _subItems['ID'] = !_fieldDetails?.RowId
                    ? null
                    : _fieldDetails?.RowId;

                // Set Child Item TypeDefId
                if (!_subItems['TypeDefId'])
                  _subItems['TypeDefId'] =
                    _fieldDetails?.TreeDataTypeDefId ?? null;

                // Add @enweb.Save if the child item is new
                if (
                  !_fieldDetails?.RowId &&
                  !_fieldDetails?.deleted &&
                  _fieldDetails?.IsCollection
                )
                  _subItems['@enweb.Save'] = true;

                // add @enweb.Delete and @enweb.Save for deleting
                if (
                  _fieldDetails?.RowId &&
                  _fieldDetails?.deleted &&
                  _fieldDetails?.IsCollection
                ) {
                  _subItems['@enweb.Delete'] = true;
                  _subItems['@enweb.Save'] = true;
                }

                if (!_fieldDetails?.deleted) {
                  _subItems[_fieldDetails?.Name] = _value ?? null;
                }

                // Format the value if Perspective Class
                if (
                  !_fieldDetails?.deleted &&
                  isPerspectiveClassType &&
                  _value
                ) {
                  _subItems[_fieldDetails?.Name] = {
                    ID: _value?.ID,
                    TypeDefId: _value?.TypeDefId,
                    '@enweb.Save': true,
                  };

                  _subItems[`${_fieldDetails?.Name}Id`] = _value?.ID;
                }
              } else getSubItems(_item.Items);
            });

            if (!isEmpty(_subItems))
              formattedData[fieldDetails?.Name].push(_subItems);
          };

          getSubItems(item.Items);
        }
      });

      return formattedData;
    };

    const formattedData = formatTreeGridData(_data);

    setValue(formattedData);
  };

  // Get the Business Object value.
  const { data: businessTypePropertyDetails } =
    useGetBusinessTypePropertiesPanelValuesQuery(
      { typeDefId, itemId },
      { skip: !typeDefId || !itemId }
    );

  // Get the property value
  const propertyValue =
    businessTypePropertyDetails?.BusinessObject?.[propertyName];

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '75rem',
          maxHeight: '56.188rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box background='none' height='100%'>
          <CustomTypeField
            onChange={onChange}
            propertyValue={propertyValue}
            businessObject={businessObject}
            propertyDetails={propertyDetails}
            treeGridProperties={treeGridProperties}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={isEqual(value, formValue) || isLoading}
          onClick={() =>
            !!onSave &&
            onSave(
              value?.[propertyName]?.length ? value?.[propertyName] : value,
              propertyName
            )
          }
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
