import {
  Add16Regular,
  Edit16Regular,
  Delete16Regular,
  Checkmark20Filled,
} from '@fluentui/react-icons';
import { DatePicker } from '@syncfusion/ej2-react-calendars';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { TextBox, NumericTextBox } from '@syncfusion/ej2-react-inputs';
import {
  Sort,
  Edit,
  Freeze,
  Resize,
  Inject,
  Toolbar,
  ColumnDirective,
  TreeGridComponent,
  ColumnsDirective,
  EditSettingsModel,
} from '@syncfusion/ej2-react-treegrid';
import { memo, useEffect, useMemo, useRef } from 'react';
import { useSearch } from '../hooks/useSearch';
import { useGetEnumsQuery, useGetPerspectiveClassesQuery } from '../services';
import { ItemBusinessClass } from '../types';
import { formatCustomTypeDate } from '../util';
import { Box } from './Box';
import { TextIconButton } from './button';
import { PerspectiveClassChip } from './chip';
import {
  getEnumValues,
  getPerspectiveClassValues,
} from './dialog/components/item/customTypeUtils';
import { isPerspectiveClass } from './item/ItemPropertiesUtil';
import {
  isBoolean,
  isDate,
  isEntity,
  isEnum,
  isInteger,
  isDouble,
} from './item/utils';
import { SearchField } from './SearchField';

export type CustomTypeTreeGrid = {
  treeGridData: any;
  isAllowAdding: boolean;
  isAllowEditing: boolean;
  isAllowDeleting: boolean;
  selectedRow: { [key: string]: any };
  handleAdd: () => void;
  handleDelete: () => void;
  rowDeselected: () => void;
  rowSelected: (args) => void;
  handleActionComplete: (args) => void;
};

export const CustomTypeTreeGrid = ({
  selectedRow,
  treeGridData,
  handleAdd,
  rowSelected,
  handleDelete,
  rowDeselected,
  handleActionComplete,
}: CustomTypeTreeGrid) => {
  const treeGridRef = useRef(null);

  // Setting Tree Grid Data Source
  useEffect(() => {
    treeGridRef.current.dataSource = treeGridData;

    // Save the tree grid data in the local storage.
    // There's an issue on the structure of the tree grid data after editing
    // Just a workaround to fix that but please free to update the code.
    localStorage.setItem(
      'customTypeTreeGridData',
      JSON.stringify(treeGridData)
    );

    return () => localStorage.removeItem('customTypeTreeGridData');
  }, [treeGridData]);

  // Setting Add, Edit, Delete (enable/disable toolbar button)
  useEffect(() => {
    const _selectedRow = treeGridRef.current.getSelectedRecords()?.[0];

    const _isAllowAdding =
      ((!_selectedRow && true) || _selectedRow?.fieldDetails?.IsCollection) ??
      false;
    const _isAllowDeleting =
      (!!_selectedRow?.fieldDetails
        ? !!_selectedRow?.childRecords?.length &&
          !!_selectedRow?.fieldDetails?.IsCollection
        : !!_selectedRow?.childRecords?.length) ?? false;
    const _isAllowEditing =
      (!!_selectedRow &&
        !_selectedRow?.fieldDetails?.IsCollection &&
        !_selectedRow?.childRecords?.length) ??
      false;

    treeGridRef.current.editSettings.allowAdding = _isAllowAdding;
    treeGridRef.current.editSettings.allowDeleting = _isAllowDeleting;
    treeGridRef.current.editSettings.allowEditing = _isAllowEditing;
  });

  const { data: enums } = useGetEnumsQuery({});
  const { data: _perspectiveClasses } = useGetPerspectiveClassesQuery({});

  // tree grid search functionality
  const { handleSearch } = useSearch({
    treeGridData: treeGridData,
    gridRef: treeGridRef,
  });

  const editOptions: EditSettingsModel = {
    mode: 'Cell',
  };

  let elem;
  let field;
  let clickTimeout;
  let clickCount = 0;
  let updatedPerspectiveClass = null;

  const isAllowAdding = treeGridRef.current?.editSettings?.allowAdding ?? false;
  const isAllowDeleting =
    treeGridRef.current?.editSettings?.allowDeleting ?? false;
  const isAllowEditing =
    treeGridRef.current?.editSettings?.allowEditing ?? false;

  // Function to handle single click
  const handleSingleRowClick = () => {
    const _selectedRow = treeGridRef.current.getSelectedRecords()?.[0];

    // Perform single click action here
    if (!_selectedRow) !!rowDeselected && rowDeselected();
  };

  const editTemplate = {
    create: () => {
      elem = document.createElement('input');
      return elem;
    },
    read: () => {
      const isPerspectiveClassType =
        isEntity(selectedRow?.fieldDetails) &&
        selectedRow?.fieldDetails?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
        selectedRow?.fieldDetails?.PropTypeDef?.Category?.InternalName ===
          'Inheritance';

      if (isPerspectiveClassType) return updatedPerspectiveClass;
      else return field?.value;
    },
    destroy: () => {
      field?.destroy();
    },
    write: (args) => {
      const { rowData } = args;
      const { fieldDetails } = rowData;

      let value = args.rowData[args.column.field];

      const isDateType = isDate(fieldDetails);
      const isEnumType = isEnum(fieldDetails);
      const isBooleanType = isBoolean(fieldDetails);
      const isIntegerType = isInteger(fieldDetails);
      const isDoubleType = isDouble(fieldDetails);
      const isPerspectiveClassType =
        isEntity(fieldDetails) &&
        fieldDetails?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
        fieldDetails?.PropTypeDef?.Category?.InternalName === 'Inheritance';

      if (isDateType) {
        field = new DatePicker({
          value,
          format: 'yyyy-MM-ddT00:00:00',
          showTodayButton: false,
          showClearButton: false,
          cssClass: 'custom-type-datepicker',
        });

        field.appendTo(elem);
      } else if (isEnumType) {
        const { Caption } = fieldDetails;

        const enumValues = getEnumValues(enums, Caption);
        const sortOrder = 'Ascending';

        field = new DropDownList({
          value,
          fields: { text: 'Caption', value: 'name' },
          dataSource: enumValues,
          cssClass: 'custom-type-dropdown',
          sortOrder,
          popupHeight: '245px',
        });

        field.appendTo(elem);
      } else if (isIntegerType) {
        field = new NumericTextBox({
          value,
          decimals: 0,
          cssClass: 'custom-type-numeric',
        });

        field.appendTo(elem);
      } else if (isDoubleType) {
        field = new NumericTextBox({
          value,
          decimals: 2,
          cssClass: 'custom-type-numeric',
        });

        field.appendTo(elem);
      } else if (isBooleanType) {
        field = new DropDownList({
          value: value?.toString(),
          fields: { text: 'label', value: 'value' },
          dataSource: [
            { label: '', value: '' },
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ],
          cssClass: 'custom-type-dropdown',
        });

        field.appendTo(elem);
      } else if (isPerspectiveClassType) {
        const propTypeDefId = fieldDetails?.PropTypeDefId;
        const perspectiveClasses = getPerspectiveClassValues(
          _perspectiveClasses,
          propTypeDefId,
          ''
        );

        if (value) updatedPerspectiveClass = value;
        else {
          value = {
            ID: null,
            TypeDefId: null,
            Active: true,
          };
        }

        field = new DropDownList({
          value: value?.ID,
          dataSource: perspectiveClasses,
          fields: {
            text: '_Display',
            value: 'ID',
          },
          select: (res) => {
            perspectiveClasses.forEach((val: ItemBusinessClass) => {
              if (
                val.ID === res?.itemData?.ID ||
                (!val.ID && !res?.itemData?.ID)
              ) {
                updatedPerspectiveClass = val;
              }
            });
          },
          cssClass: 'custom-type-dropdown',
        });
        field.appendTo(elem);
      } else {
        field = new TextBox({
          value,
        });

        field.appendTo(elem);
      }
    },
  };

  const memoizedValueColumnTemplate = useMemo(() => {
    return (item) => {
      // Render perspective class
      if (item.value && isPerspectiveClass(item.value))
        return <PerspectiveClassChip businessClass={item.value} />;
      // Render boolean
      else if (
        (item.value !== null || item.value !== '') &&
        isBoolean(item?.fieldDetails)
      ) {
        if (item.value === true || item.value === 'true') return 'Yes';
        else if (item.value === false || item.value === 'false') return 'No';
      }
      // Render date
      else if (item.value && isDate(item?.fieldDetails))
        return formatCustomTypeDate(item.value);

      return item.value;
    };
  }, []);

  const memoizedImportantColumnTemplate = useMemo(() => {
    return (item) => {
      switch (item.important) {
        case 'Yes':
          return (
            <Box background='none'>
              <Checkmark20Filled />
            </Box>
          );
        case 'No':
          return '';
        default:
          return item.important;
      }
    };
  }, []);

  const load = (): void => {
    let instance = (
      document.getElementById('item-custom-type-tree-grid') as any
    ).ej2_instances[0];
    if (instance) {
      instance.element.addEventListener('click', function (e) {
        if (
          (e.target as HTMLElement).classList.contains('e-rowcell') ||
          (e.target as HTMLElement).classList.contains('e-treecell') ||
          (e.target as HTMLElement).classList.contains('MuiTypography-body2')
        ) {
          clickCount++;
          // If it's the first click, set a timeout to detect double click
          if (clickCount === 1) {
            clickTimeout = setTimeout(() => {
              // If the timeout completes, it's a single click
              handleSingleRowClick();
              // Reset the click count
              clickCount = 0;
            }, 300); // You can adjust the timeout duration (in milliseconds)
          } else if (clickCount === 2) {
            // If it's the second click before the timeout, it's a double click
            clearTimeout(clickTimeout);
            const isEdit = treeGridRef.current.grid.isEdit;

            if (!isEdit)
              setTimeout(() => {
                handleSingleRowClick();
              }, 300);
            // Reset the click count
            clickCount = 0;
          }
        }
      });
    }
  };

  return (
    <>
      <Box
        direction='row'
        background='none'
        alignItems='center'
        justifyContent='space-between'
        style={{ marginBottom: 0, paddingTop: 0, minHeight: '5.5rem' }}
      >
        <SearchField
          height='3rem'
          width='17.188rem'
          onHandleSearch={handleSearch}
        />
        <Box background='none' direction='row' justifyContent='end'>
          <TextIconButton
            text='Add'
            disabled={!isAllowAdding}
            startIcon={<Add16Regular />}
            sx={{ padding: '0.5rem 1.5rem' }}
            onClick={handleAdd}
          />
          <TextIconButton
            text='Delete'
            disabled={!isAllowDeleting}
            startIcon={<Delete16Regular />}
            sx={{ padding: '0.5rem 1.5rem' }}
            onClick={handleDelete}
          />
          <TextIconButton
            text='Edit'
            disabled={!isAllowEditing}
            startIcon={<Edit16Regular />}
            sx={{ padding: '0.5rem 1.5rem' }}
            onClick={() => {
              const selectedRow = treeGridRef.current.getSelectedRowIndexes();
              const rowIndex = selectedRow[0];

              treeGridRef.current.editCell(rowIndex, 'value');
            }}
          />
        </Box>
      </Box>
      <Box background='none'>
        <TreeGridComponent
          load={load}
          height='100%'
          ref={treeGridRef}
          allowSorting={true}
          treeColumnIndex={0}
          childMapping='Items'
          allowResizing={true}
          rowSelected={rowSelected}
          editSettings={editOptions}
          id='item-custom-type-tree-grid'
          className='item-custom-type-tree-grid'
          actionComplete={handleActionComplete}
        >
          <ColumnsDirective>
            <ColumnDirective
              width='448'
              field='propertyName'
              allowEditing={false}
              headerText='PROPERTY NAME'
            />
            <ColumnDirective
              width='448'
              field='value'
              edit={editTemplate}
              headerText='PROPERTY VALUE'
              template={memoizedValueColumnTemplate}
            />
            <ColumnDirective
              field='important'
              allowEditing={false}
              headerText='IMPORTANT'
              template={memoizedImportantColumnTemplate}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Edit, Toolbar, Resize, Freeze]} />
        </TreeGridComponent>
      </Box>
    </>
  );
};

export const MemoizedCustomTypeTreeGrid = memo(CustomTypeTreeGrid);
