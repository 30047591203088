import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, setCurrentItem, useAppSelector } from '../store';
import {
  useGetBusinessTypePropertiesPanelValuesQuery,
  useGetItemTypePropertiesQuery,
  useGetTreeGridDataQuery,
} from '../services';
import { useBusinessType } from './useBusinessType';
import { useIcon } from './useIcon';
import { useItemDetails } from './useItemDetails';
import { useNavigate } from 'react-router-dom';
import { OpenInContextMenuTarget } from '../components';
import { Item } from '../types';
import { useBusinessTypePropertiesPanel } from './useBusinessTypePropertiesPanel';
import { merge } from 'lodash';

export const useItemPanel = ({
  id,
  initialTab,
  onSelectTab,
  disposalRequest,
  setId,
}) => {
  const navigate = useNavigate();
  // -----------------------------
  // State Declarations
  // -----------------------------

  // State for tab management
  const [selectedTab, setSelectedTab] = useState(initialTab);

  // State for item and related images/types
  const [businessTypeImageId, setBusinessTypeImageId] = useState<string>();
  const [businessTypeId, setBusinessTypeId] = useState<string>();
  const [typeDefImageId, setTypeDefImageId] = useState<string>();
  const [itemImageId, setItemImageId] = useState<string>();
  const [typeDefId, setTypeDefId] = useState<string>();
  const [itemId, setItemId] = useState<string>();
  const [selectedCheckbox, setSelectedCheckbox] = useState<any[]>([]);
  const [fullScreenTab, setFullScreenTab] = useState<boolean>(false);
  const [hasPreviewableContent, setHasPreviewableContent] = useState<boolean>(false);

  const gridRef = useRef(null);

  // -----------------------------
  // Data Fetching Hooks
  // -----------------------------

  // Fetch item details based on the given ID
  const { itemDetails, itemCollectionCount, propLinksData } = useItemDetails({
    id,
  });

  const dispatch = useAppDispatch();
  const levels = JSON.stringify(
    useAppSelector((state) => state.itemModal.navigation.levels)
  );

  // this is for gettng some of the tree grid data property name.
  const { data: treeGridProperties } = useGetTreeGridDataQuery(
    {
      businessTypeId,
    },
    { skip: !businessTypeId }
  );

  // Fetch properties related to the item type
  const { data: propertyDetails } = useGetItemTypePropertiesQuery(
    { typeDefId, itemId },
    { skip: !typeDefId || !itemId }
  );

  // Fetch business type properties and icons for a given item
  const {
    businessTypeProperties,
    businessTypePropertyValues: propertyValues,
    businessTypeIconDetails,
  } = useBusinessType({ item: itemDetails });


  // Get the Business Object value.
  const { data: businessTypePropertyDetails } =
    useGetBusinessTypePropertiesPanelValuesQuery(
      { typeDefId, itemId },
      { skip: !typeDefId || !itemId || !businessTypeId }
    );

  // Fetch icon details based on various image IDs with a fallback
  const { iconDetails } = useIcon({
    imageId: businessTypeImageId ?? typeDefImageId ?? itemImageId,
    fallbackObj: itemDetails,
  });

  // this is for gettng the business type properties
  const { businessTypeTreeGridData } = useBusinessTypePropertiesPanel({
    item: itemDetails as Item,
    businessTypeProperties,
    businessTypePropertyValues: propertyValues,
    treeGridProperties,
  });

  // -----------------------------
  // Effects
  // -----------------------------

  // Effect to update state values when itemDetails changes
  useEffect(() => {
    setBusinessTypeImageId(itemDetails?.BusinessType?._ImageId);
    setTypeDefImageId(itemDetails?.TypeDef?._ImageId);
    setBusinessTypeId(itemDetails?.BusinessTypeId);
    setItemImageId(itemDetails?._ImageId);
    setTypeDefId(itemDetails?.TypeDefId);
    setItemId(itemDetails?.ID);
  }, [itemDetails, id]);

  // Effect to manage tab state and selection
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setTypeDefId(itemDetails?.TypeDefId);
  }, [initialTab, id]);

  //Effect to manage layout of tabs
  useEffect(() => {
    setFullScreenTab(['items'].some((x) => x === selectedTab));
  }, [selectedTab, id]);

  /*Effect to refresh the grid. This is a hack by navigation
  to whomever refactors this gridRef.current.refresh doesn't work as it doesn't refresh the binding*/
  useEffect(() => {
    if (gridRef.current) {
      const buttonsInPager = gridRef.current
        .getPager()
        .querySelectorAll('button');

      if (buttonsInPager.length > 1) {
        buttonsInPager[0].click();
      } else {
        console.error('No buttons found');
      }

      gridRef.current.refresh();
    }
  }, [levels]);

  // -----------------------------
  // Handlers
  // -----------------------------

  const setPreviewState = (state) => {
    setHasPreviewableContent(state);
  };

  // Handler to manage tab selection and prop callback
  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab);
    onSelectTab && onSelectTab(tab);
  };

  const navigateBreadcrumbs = (item: Item) => {
    setId(item.ID);
    setItemId(item.ID);
    if (gridRef.current) {
      const buttonsInPager = gridRef.current
        .getPager()
        .querySelectorAll('button');

      if (buttonsInPager.length > 1) {
        buttonsInPager[0].click();
      } else {
        console.error('No buttons found');
      }

      gridRef.current.refresh();
    }

  };

  const handleOpen = (
    item: Item,
    target?: OpenInContextMenuTarget,
    effect?: () => void
  ) => {
    !effect ? setSelectedTab('summary') : effect();
    dispatch(setCurrentItem({ id: item.ID, name: item.DisplayName }));

    const _target = target ?? 'modal';
    switch (_target) {
      case 'new tab':
      case 'new window':
        window.open(
          `/item/${item.ID}?requestId=${id}${
            (disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
            ''
          }`,
          '_blank'
        );
        break;
      case 'chromeless page':
        window.open(`/itempanel/${item.ID}`, '_blank');
        break;
      case 'modal':
        navigateBreadcrumbs(item);
        break;
      case 'new page':
      default:
        navigate(
          `/item/${item.ID}?requestId=${id}${(disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
          ''
          }`
        );
        break;
    }
  };

  const handleOpenItems = (item: Item, target?: OpenInContextMenuTarget) => {
    onSelectTab && onSelectTab('items');
    handleOpen(item, target, () => setSelectedTab('items'));
  };

  const handleOpenURL = (url?: string) => {
    if (url) window.open(url, '_blank');
  };

  const handleCheckbox = (item: any) => {
    setSelectedCheckbox(item);
    console.log('Checkbox', item);
  };

  const showColumnChooser = () => {
    if (gridRef?.current) {
      gridRef.current.openColumnChooser(0, 0);
    }
  };

  const businessTypePropertyValues = merge({}, propertyValues, businessTypePropertyDetails?.BusinessObject)

  return {
    iconDetails,
    itemDetails,
    selectedTab,
    handleSelectTab,
    propertyDetails,
    treeGridProperties,
    businessTypeProperties,
    businessTypeIconDetails,
    businessTypePropertyValues,
    itemCollectionCount,
    fullScreenTab,
    handleCheckbox,
    handleOpen,
    handleOpenURL,
    showColumnChooser,
    handleOpenItems,
    setPreviewState,
    selectedCheckbox,
    gridRef,
    businessTypeTreeGridData,
    levels,
    hasPreviewableContent,
    propLinksData,
  };
};
