import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { addCollection, constructTreeGridData, deepMergeArrays, removeItem } from "../components/dialog/components/item/customTypeUtils";

export enum ActionType {
  Delete = "DELETE",
}

export const useCustomTypeActions = ({  
    onChange,
    propertyValue,
    businessObject,
    propertyDetails,
    treeGridProperties 
}) => {
  const isCollection = propertyDetails?.IsCollection;

  const [treeGridData, setTreeGridData] = useState([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [isAllowAdding, setIsAllowAdding] = useState(false);
  const [isAllowDeleting, setIsAllowDeleting] = useState(false);
  const [isAllowEditing, setIsAllowEditing] = useState(false);

  const treeGridWithDeletedPropertyRef = useRef([]);

  useEffect(() => {
    const _isAllowAdding =
    ((!selectedRow && isCollection) || selectedRow?.fieldDetails?.IsCollection) ?? false;
    const _isAllowDeleting = (!!selectedRow?.fieldDetails ? !!selectedRow?.childRecords?.length && !!selectedRow?.fieldDetails?.IsCollection : !!selectedRow?.childRecords?.length) ?? false;
    const _isAllowEditing =
      (!!selectedRow &&
      !selectedRow?.fieldDetails?.IsCollection &&
      !selectedRow?.childRecords?.length) ?? false;

    setIsAllowAdding(_isAllowAdding);
    setIsAllowDeleting(_isAllowDeleting);
    setIsAllowEditing(_isAllowEditing);
  }, [selectedRow]);

  useEffect(() => {
    if (!!treeGridProperties || !!propertyDetails) {
      const data = constructTreeGridData(
        propertyValue,
        businessObject,
        propertyDetails,
        treeGridProperties,
      );
      
      setTreeGridData([...data]);

      treeGridWithDeletedPropertyRef.current = data;

      localStorage.setItem(
        'treeGridWithDeletedPropertyRef',
        JSON.stringify(treeGridWithDeletedPropertyRef.current)
      );
    }

    return () => localStorage.removeItem("treeGridWithDeletedPropertyRef")
  }, []);

  // Add button functionality
  const handleAdd = () => {
    // this is to add a new item if the selected element is a collection
    if (!!selectedRow && selectedRow?.fieldDetails?.IsCollection) {
      const { propertyName, fieldDetails, parentItem } = selectedRow;
      // set the selected id 
      const id = parentItem?.id ?? selectedRow?.id;

      const newTreeGridData = [...treeGridData];
     
      addCollection(newTreeGridData, id,  propertyName, fieldDetails, treeGridProperties, businessObject);

      setTreeGridData(newTreeGridData);

      const result = deepMergeArrays(treeGridWithDeletedPropertyRef.current, newTreeGridData, '');
      treeGridWithDeletedPropertyRef.current = result;

      localStorage.setItem(
        'treeGridWithDeletedPropertyRef',
        JSON.stringify(treeGridWithDeletedPropertyRef.current)
      );
      
      !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
    }
    // add a new parent item
    else {
      const newTreeGridData = [
        ...treeGridData,
        ...constructTreeGridData('', businessObject, propertyDetails, treeGridProperties),
      ];

      setTreeGridData(newTreeGridData);

      const result = deepMergeArrays(treeGridWithDeletedPropertyRef.current, newTreeGridData, '');
      treeGridWithDeletedPropertyRef.current = result;

      localStorage.setItem(
        'treeGridWithDeletedPropertyRef',
        JSON.stringify(treeGridWithDeletedPropertyRef.current)
      );

      !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
    }
  };
  
  // Delete button functionality
  const handleDelete = () => {
    if (!isAllowDeleting) return;

    const id = selectedRow?.id;
    const targetDepth = selectedRow?.level;

    const treeDataWithDeletedProperty = [...treeGridWithDeletedPropertyRef.current];

    const processChildItems = (_item) => {
      _item?.forEach(_item => {
        if (!!_item?.fieldDetails) {
          _item.fieldDetails['deleted'] = true;
        }

        if (_item?.Items?.length > 0) processChildItems(_item.Items);
      });
    }

    const updateParentItems = (_treeGridData) => {
      _treeGridData.forEach((_item) => {
        if (_item.id === id) {
          processChildItems(_item.Items);
        }
        else updateParentItems(_item.Items);
      });        
    }

    updateParentItems(treeDataWithDeletedProperty);

    // remove collection
    const newTreeGridData = removeItem(treeGridData, targetDepth, 0, selectedRow, id);

    setSelectedRow(null);
    setTreeGridData(newTreeGridData);

    localStorage.setItem(
      'treeGridWithDeletedPropertyRef',
      JSON.stringify(treeGridWithDeletedPropertyRef.current)
    );

    !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
  };
  
  // Edit button functionality
  const handleActionComplete = (args) => {
    if (args.type === 'save') {
      const id = args?.data?.id;
      const currentValue = args?.data?.value;
      
      // getting the data from the local storage.
      const _treeGridData = localStorage.getItem("customTypeTreeGridData");
      const _treeGridDataRef = localStorage.getItem("treeGridWithDeletedPropertyRef");

      if (_treeGridData) {
        const treeGridData = JSON.parse(_treeGridData);
        const treeGridDataRef = JSON.parse(_treeGridDataRef)

        let isUpdated = false;
        const updatePropertyValue = (data, propertyId, newValue) => {
          for (const item of data) {
            if (isUpdated) return;
            if (item.id === propertyId) {
              item.value = newValue;
              isUpdated = true;
            }
            if (item.Items && item.Items.length > 0) {
              updatePropertyValue(item.Items, id, newValue);
            }
          }
        }

        updatePropertyValue(treeGridData, id, currentValue);

        // update the tree grid after getting the result
        setTreeGridData(treeGridData);

        const result = deepMergeArrays(treeGridData, treeGridDataRef, 'complete');
        treeGridWithDeletedPropertyRef.current = result;

        localStorage.setItem(
          'treeGridWithDeletedPropertyRef',
          JSON.stringify(treeGridWithDeletedPropertyRef.current)
        );

        !!onChange && onChange({ data: treeGridWithDeletedPropertyRef.current, propertyName: propertyDetails?.Name });
      }

      // reset the selected row state
      setTimeout(() => {
        setSelectedRow(null);
      }, 100)
    }
  };

  // Row Selected functionality
  const handleRowSelected = (args) => {
    const { data } = args; 

    setSelectedRow(data);
  };
  
  // Row Deselected functionality
  const handleRowDeselected = () => {
    setSelectedRow(null);
  };

  // Row Selected functionality
  const handleRecordDoubleClick = (args) => {
    const { rowData } = args; 

    setSelectedRow(rowData);
  };

  return { 
    selectedRow,
    treeGridData,
    isCollection,
    isAllowAdding,
    isAllowDeleting,
    isAllowEditing,
    handleAdd,
    handleDelete,
    handleRowSelected,
    handleRowDeselected,
    handleActionComplete,
    handleRecordDoubleClick,
  }; 
}