import { Divider } from '@mui/material';
import {
  DisposalRequest,
  DisposalRequestStatus,
  User
} from '../types';
import { Box } from './Box';
import { formatDate } from '../util';
import { DetailsRow } from './DetailsRow';
import { useGetItemDisposalDetailsQuery } from '../services';
import { DisposalRequestApprovers } from './DisposalRequestApprovers';
import { InfoPanel } from './InfoPanel';
import {
  Chip,
  DisposalRequestStatusChip,
  DisposalRequestStatusIndicator,
  UserChip
} from './chip';

import {
  ArrowClockwiseDashes20Regular,
  CheckmarkSquare20Regular,
  Delete20Regular,
  DismissSquare20Regular,
  ErrorCircle20Regular,
  New20Regular,
  PeopleQueue20Regular,
} from '@fluentui/react-icons';

export type DisposalDetailsPanelProps = {
  request: DisposalRequest;
  itemId?: string;
};

export const DisposalDetailsPanel = ({
  request,
  itemId,
}: DisposalDetailsPanelProps) => {

  const {
    data: itemDisposalDetails,
  } = useGetItemDisposalDetailsQuery({ itemId: itemId });

  const _icon = {
    [DisposalRequestStatus.New]: <New20Regular />,
    [DisposalRequestStatus.InApproval]: <PeopleQueue20Regular />,
    [DisposalRequestStatus.Approved]: <CheckmarkSquare20Regular />,
    [DisposalRequestStatus.Rejected]: <DismissSquare20Regular />,
    [DisposalRequestStatus.InProgress]: <ArrowClockwiseDashes20Regular />,
    [DisposalRequestStatus.Complete]: <Delete20Regular />,
    [DisposalRequestStatus.Failed]: <ErrorCircle20Regular />,
  };

  // TODO: clean up below (for demo)
  const dateCompleted = itemDisposalDetails?.DisposalDetails?.value[0]?.CompletedDate ? itemDisposalDetails.DisposalDetails.value[0]?.CompletedDate : request?.DateRequested;

  return (
    <Box background='none' style={{ overflow: 'auto', padding: '0 0 0 3.6rem' }}>

      {request.Status === DisposalRequestStatus.Complete && (
        <>
          <InfoPanel title='Disposal Details' width={46}>
            <DetailsRow
              label='Date Disposed'
              data={formatDate(dateCompleted)}
              alignData
            />
            <DetailsRow
              label='Disposed by'
              data={
                request.RequestedBy?.DisplayName && (
                  <UserChip
                    border={false}
                    user={{ name: request.RequestedBy?.DisplayName } as User}
                  />
                )
              }
              alignData
            />
            <DetailsRow
              label='Disposal Action'
              data='Destroy'
              alignData
            />
            <DetailsRow
              label='Additional Information'
              data={itemDisposalDetails?.DisposalDetails.value &&
                itemDisposalDetails.DisposalDetails.value[0]?.AdditionalInformation}

              alignData
            />
          </InfoPanel>
          <Divider style={{ margin: '1.25rem 0 1.25rem 0' }} />
        </>
      )}


      <InfoPanel title='Disposal Approval' width={46}>
        <DetailsRow
          label='Disposal Requests'
          data={
            <Chip
              border={true}
              title={request?.Name}
              subTitle={request?.Name}
              icon={_icon[request?.Status]}
              internalChip={<DisposalRequestStatusIndicator status={request.Status} />}
            />
          }
          alignData
        />
        <DetailsRow
          label='Approvers'
          data={
            (request?.ApproverResponses?.value?.length ?? 0) > 0 ? (
              <DisposalRequestApprovers
                responses={request?.ApproverResponses?.value}
                width={24}
              />
            ) : (
              <>
                <DisposalRequestStatusChip status={request.Status} />
              </>
            )
          }
          alignData
        />
      </InfoPanel>
    </Box>
  );
};
