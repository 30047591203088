import dayjs from "dayjs";

//
export const formatDate = (isoDateString: string): string => {
  const dateObject = new Date(isoDateString);

  const twoDigitDay = padSingleDigitWithZero(dateObject.getDate());
  const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1);
  const fourDigitYear = dateObject.getFullYear();

  const twoDigitHour = padSingleDigitWithZero(dateObject.getHours());
  const twoDigitMinute = padSingleDigitWithZero(dateObject.getMinutes());

  return `${twoDigitDay}/${twoDigitMonth}/${fourDigitYear} ${twoDigitHour}:${twoDigitMinute}`;;
};

export const formatCustomTypeDate = (isoDateString: string): string => {
  const dateObject = new Date(isoDateString);

  const twoDigitDay = padSingleDigitWithZero(dateObject.getDate());
  const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1);
  const fourDigitYear = dateObject.getFullYear();

  return `${fourDigitYear}-${twoDigitMonth}-${twoDigitDay}T00:00:00`;;
};

const padSingleDigitWithZero = (number: number): string => {
  return number < 10 ? `0${number}` : `${number}`;
};

export const getFormattedDateTime = (dateTimeStr: string) => {
  if (!dateTimeStr) return null;
  return dayjs(dateTimeStr).format("MM/DD/YYYY HH:mm");
}