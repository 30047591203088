import { styled, Typography, useTheme } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { useIcon, useFileDetails } from '../hooks';
import { Item } from '../types';
import { Box } from './Box';
import { Alert } from './alert';
import { ItemEmptyState } from './item';

const StyledImage = styled('img')``;

export const FileViewer = ({
  itemDetails,
  rowData,
  setPreviewState,
}: {
  itemDetails: Item;
  rowData: any;
  setPreviewState: (state: boolean) => void;
}) => {
  const theme = useTheme();

  const viewer = useRef(null);
  const iframeRef = useRef(null);

  const [alertError, setAlertOpen] = useState(false);

  const typeDefImageId = itemDetails?.TypeDef?._ImageId;
  const itemImageId = itemDetails?._ImageId;
  const referenceType = itemDetails?.TypeDef?.Name;

  const { iconDetails } = useIcon({
    imageId: itemImageId ?? typeDefImageId,
  });

  const { fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl, error } =
    useFileDetails({
      item: itemDetails,
      viewer,
      rowData,
    });

  useEffect(() => {
    !!setPreviewState &&
      setPreviewState(
        !!fileDetails || !!htmlData || !!docBlobUrl || !!pdfJsonBlobUrl
      );
  }, [fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl]);

  useEffect(() => {
    if (error) {
      setAlertOpen(true);
    }
  }, [error]);

  let contentViewer = null;

  if (fileDetails || htmlData || docBlobUrl || pdfJsonBlobUrl) {
    if (fileDetails) {
      contentViewer = (
        <>
          <Box
            width='100%'
            direction='row'
            alignItems='center'
            background='light'
            style={{
              padding: '0.875rem 1.813rem',
              marginLeft: '1.813rem',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            {iconDetails ? (
              <StyledImage
                src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
                alt={iconDetails?.AltText}
                style={{ height: '1.5rem' }}
              />
            ) : null}
            <Typography variant='h5' paddingLeft='1.063rem'>
              {itemDetails._Display}
            </Typography>
          </Box>
          <div ref={viewer} style={{ height: '100%', width: '100%' }}></div>
        </>
      );
    } else if (htmlData) {
      contentViewer = (
        <>
          <Box
            width='100%'
            direction='row'
            alignItems='center'
            background='light'
            style={{
              padding: '0.875rem 1.563rem',
              marginLeft: '3.125rem',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant='h5'>
              {referenceType === 'PostBasedConversation'
                ? 'Conversation'
                : itemDetails._Display}
            </Typography>
          </Box>
          <iframe
            title='Preview'
            ref={iframeRef}
            srcDoc={htmlData}
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        </>
      );
    } else if (docBlobUrl) {
      contentViewer = (
        <>
          <Box
            width='100%'
            direction='row'
            alignItems='center'
            background='light'
            style={{
              padding: '0.875rem 1.563rem',
              marginLeft: '3.125rem',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant='h5' paddingLeft='1.063rem'>
              {itemDetails._Display}
            </Typography>
          </Box>
          <div ref={viewer} style={{ width: '100%', height: '100%' }} />
        </>
      );
    } else if (pdfJsonBlobUrl) {
      contentViewer = (
        <>
          <Box
            width='100%'
            direction='row'
            alignItems='center'
            background='light'
            style={{
              padding: '0.875rem 1.563rem',
              marginLeft: '3.125rem',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant='h5'>{itemDetails._Display}</Typography>
          </Box>
          <iframe
            title='Eigen Preview'
            src={pdfJsonBlobUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        </>
      );
    }
  } else if (error) {
    contentViewer = (
      <>
        <Alert
          alertType='warning'
          message={error}
          open={alertError}
          onClose={() => {
            setAlertOpen(false);
          }}
          autoHideDuration={10000}
        />
        <ItemEmptyState repositoryUrl={itemDetails.RepositoryUrl} />
      </>
    );
  } else {
    contentViewer = '';
  }

  return <>{contentViewer}</>;
};
