import { DataStateChangeEventArgs } from '@syncfusion/ej2-react-treegrid';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Item, ItemGridQuery } from '../../types';

import { DataResult } from '@syncfusion/ej2-react-grids';
import { config } from '../../config';
import { acquireToken } from '../../util/data-utils';

const useItemsGrid = ({
  onSelect,
  pageSize,
  disposalId,
  data,
  adminMode,
  filter,
  selectedRecords,
  gridRef,
  itemGridType,
}: {
  onSelect?: (request: any) => void;
  pageSize: number;
  disposalId?: string;
  data?: Item[];
  adminMode?: boolean;
  filter?: string;
  selectedRecords?: any[];
  gridRef?: any;
  itemGridType: ItemGridQuery;
}) => {
  const key = Math.random();

  const BASE_URL: string = `${config.API_BASE_URL}/Enc.Item`;
  const execute = (state: DataStateChangeEventArgs): Promise<DataResult> => {
    if (state.requestType === 'expand') {
      return getChildData(state);
    }
    if (
      (state.action ?? { requestType: 'undefined' }).requestType ===
        'sorting' &&
      (state.action as any).columnName != undefined
    ) {
      return getSortData(state);
    }
    return getData(state);
  };

  const getSortData = async (
    state: DataStateChangeEventArgs
  ): Promise<DataResult> => {
    const token = !!data ? '' : await acquireToken();
    const pageQuery = `$skip=${state.skip}&$top=${state.take}`;
    const sortQuery = `$orderby=${(state.action as any).columnName} ${
      (state.action as any).direction == 'Descending' ? 'desc' : ''
    }`;

    //Query to select the parent
    const getTreeGridQuery = () => {
      console.log('getTreeGridQuery', itemGridType);

      switch (String(itemGridType)) {
        case ItemGridQuery.ChildWithStatus:
          return `$filter=MemberOf/Any(x:x/ID eq ${disposalId} and Status ne 'None')) or (AttachmentOfId eq ${disposalId} and Status ne 'None'))&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=*)&${sortQuery}`;
        case ItemGridQuery.Child:
          return `$filter=MemberOf/Any(x:x/ID eq ${disposalId}) or (AttachmentOfId eq ${disposalId})&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems, CreatedBy, MemberOf, Members($count=true), DisposalRequests, DisposalStates($select=*)&${sortQuery}`;
        case ItemGridQuery.RootWithStatus:
          return `$filter=(DisposalRequests/ID eq ${disposalId} and Status ne 'None')${
            !!filter ? ' and (' + filter + ')' : ''
          }&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems, CreatedBy, MemberOf, Members($count=true), DisposalRequests, DisposalStates($select=*)&${sortQuery}`;
        case ItemGridQuery.Root:
        default:
          return `$filter=(DisposalRequests/ID eq ${disposalId})${
            !!filter ? ' and (' + filter + ')' : ''
          }&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems, CreatedBy, MemberOf, Members($count=true), DisposalRequests, DisposalStates($select=*)&${sortQuery}`;
      }
    };

    /// filter query for fetching only the root level records
    const treegridQuery = getTreeGridQuery();

    try {
      const response = await fetch(
        `${BASE_URL}?${pageQuery}&${treegridQuery}&$inlinecount=allpages&$format=json`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      let data = await response.json();

      //TODO: Add when treegrid nesting is required
      // if (String(itemGridType) != ItemGridQuery.Child) {
      //   (data?.value ?? []).map((x: any) => {
      //     //Is Parent
      //     if (x.Members || x.AttachedItems) {
      //       x.HasMember = true;
      //     } else {
      //       x.HasMember = false;
      //     }

      //     //ParentId Mapping
      //     if (x.MemberOf !== null || x.AttachmentOfId !== null) {
      //       x.ParentId = x.MemberOf.value[0].ID;
      //     } else {
      //       x.ParentId = null;
      //     }

      //     return x;
      //   });
      // }
      return {
        count: data['@odata.count'],
        result: data.value ?? [],
      };
    } catch (error) {
      console.error(error);
    }
    return {
      count: 0,
      result: [],
    };
  };

  const getData = async (
    state: DataStateChangeEventArgs
  ): Promise<DataResult> => {
    const token = !!data ? '' : await acquireToken();
    const pageQuery = `$skip=${state.skip}&$top=${state.take}`;

    const getTreeGridQuery = () => {
      switch (String(itemGridType)) {
        case ItemGridQuery.ChildWithStatus:
          return `$filter=MemberOf/Any(x:x/ID eq ${disposalId} and Status ne 'None')) or (AttachmentOfId eq ${disposalId} and Status ne 'None'))&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=*)`;
        case ItemGridQuery.Child:
          return `$filter=MemberOf/Any(x:x/ID eq ${disposalId}) or (AttachmentOfId eq ${disposalId})&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems,CreatedBy,MemberOf,Members($count=true),DisposalRequests,DisposalStates($select=*)`;
        case ItemGridQuery.RootWithStatus:
          return `$filter=(DisposalRequests/ID eq ${disposalId} and Status ne 'None')${
            !!filter ? ' and (' + filter + ')' : ''
          }&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems, CreatedBy, MemberOf, Members($count=true), DisposalRequests, DisposalStates($select=*)`;
        case ItemGridQuery.Root:
        default:
          return `$filter=(DisposalRequests/ID eq ${disposalId})${
            !!filter ? ' and (' + filter + ')' : ''
          }&$select=*&$count=true&$expand=Classifications,TypeDef,BusinessType,StorageObject,AttachedItems, CreatedBy, MemberOf, Members($count=true), DisposalRequests, DisposalStates($select=*)`;
      }
    };

    /// filter query for fetching only the root level records
    const treegridQuery = getTreeGridQuery();

    try {
      const response = await fetch(
        `${BASE_URL}?${pageQuery}&${treegridQuery}&$inlinecount=allpages&$format=json`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      let data = await response.json();

      //TODO: Add when treegrid nesting is required
      // if (String(itemGridType) != ItemGridQuery.Child) {
      //   (data?.value ?? []).map((x: any) => {
      //     //Is Parent
      //     if (x.Members || x.AttachedItems) {
      //       x.HasMember = true;
      //     } else {
      //       x.HasMember = false;
      //     }

      //     //ParentId Mapping
      //     if (x.MemberOf !== null || x.AttachmentOfId !== null) {
      //       x.ParentId = x.MemberOf.value[0].ID;
      //     } else {
      //       x.ParentId = null;
      //     }

      //     return x;
      //   });
      // }

      return {
        count: data['@odata.count'],
        result: data.value ?? [],
      };
    } catch (error) {
      console.error(error);
    }
    return {
      count: 0,
      result: [],
    };
  };

  const getChildData = async (
    state: DataStateChangeEventArgs
  ): Promise<DataResult> => {
    const token = !!data ? '' : await acquireToken();

    const getTreeGridQuery = () => {
      switch (String(itemGridType)) {
        case ItemGridQuery.ChildWithStatus:
          return `$select=*&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true), CreatedBy, MemberOf, Members($count=true), DisposalStates($select=*), DisposalRequests&$filter=(MemberOf eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          } and Status ne 'None') or (AttachmentOfId eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          } and Status ne 'None')`;
        case ItemGridQuery.Child:
          return `$select=*&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true), CreatedBy, MemberOf, Members($count=true), DisposalStates($select=*), DisposalRequests&$filter=(MemberOf eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          }) or (AttachmentOfId eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          })`;
        case ItemGridQuery.RootWithStatus:
          return `$select=*&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true), CreatedBy, MemberOf, Members($count=true), DisposalStates($select=*), DisposalRequests&$filter=(MemberOf eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          } and Status ne 'None') or (AttachmentOfId eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          } and Status ne 'None')`;
        case ItemGridQuery.Root:
        default:
          return `$select=*&$expand=Classifications,TypeDef,StorageObject,BusinessType,AttachedItems($count=true), CreatedBy, MemberOf, Members($count=true), DisposalStates($select=*), DisposalRequests&$filter=(MemberOf eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          }) or (AttachmentOfId eq ${
            (state.data as any).ID == null ? '-1' : (state.data as any).ID
          })`;
      }
    };

    const treegridQuery = getTreeGridQuery();

    try {
      const response = await fetch(
        `${BASE_URL}?&${treegridQuery}&$inlinecount=allpages&$format=json`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      let data = await response.json();
      (data?.value ?? []).map((x: any) => {
        //Is Parent
        if (x.Members || x.AttachedItems) {
          x.HasMember = true;
        } else {
          x.HasMember = false;
        }

        //TODO: Add when treegrid nesting is required
        //ParentId Mapping
        // if (x.MemberOf !== null || x.AttachmentOfId !== null) {
        //   x.ParentId =
        //     x.MemberOf?.value.filter((x) => x.ID == x.ID).length > 0
        //       ? x.ID
        //       : null;
        // } else {
        //   x.ParentId = null;
        // }

        return x;
      });

      return data.value;
    } catch (error) {
      console.error(error);
    }
  };
  const getDataSource = () => {
    if (
      gridRef?.current &&
      gridRef?.current.dataSource instanceof Array &&
      !(gridRef?.current.dataSource as object[]).length
    ) {
      const admin = () => {
        if (gridRef?.current) {
          /** show by HeaderText */
          gridRef.current.hideColumns([
            'Extension',
            'Created By',
            'Date Created',
            'Date Modified',
          ]);
          gridRef.current.showColumns([
            'Disposal Status',
            'Business Type',
            'Status',
            'Checkbox',
          ]);
        }
      };
      const normal = () => {
        if (gridRef?.current) {
          /** hide by HeaderText */
          gridRef.current.hideColumns([
            'Disposal Status',
            'Business Type',
            'Status',
            'Checkbox',
          ]);
          gridRef.current.showColumns([
            'Extension',
            'Created By',
            'Date Created',
            'Date Modified',
          ]);
        }
      };
      if (adminMode) {
        admin();
      } else {
        normal();
      }
      const state = { skip: 0, take: pageSize }; /// take value should always be equal to the pageSize of  TreeGrid
      dataStateChange(state);
    }
  };

  const dataStateChange = (state: any): void => {
    if (state.requestType === 'expand') {
      execute(state).then((childData: any) => {
        state.childData = childData;
        state.childDataBind();
      });
      return;
    }
    if (
      (state.action ?? { requestType: 'undefined' }).requestType === 'sorting'
    ) {
      execute(state).then((treedata) => {
        if (gridRef?.current) {
          gridRef.current.dataSource = treedata;
        }
      });
      return;
    }

    execute(state).then((treedata) => {
      console.log('treedata', treedata);

      if (gridRef?.current) {
        gridRef.current.dataSource = treedata;
      }
    });
  };

  const [comment, setComment] = useState<string | null>(null);
  const [contextAnchor, setContextAnchor] = useState<Element | null>(null);
  const [contextItem, setContextItem] = useState<Item | undefined>();

  const dateFormat = { type: 'date', format: 'dd MMMM yyy' };

  const rowSelected = () => {
    if (gridRef?.current) {
      /** Get the selected records. */
      const selectedrecords = gridRef.current.getSelectedRecords();
      // setSelected(selectedrecords);
      if (onSelect) {
        console.log('selectedrecords', selectedrecords);
        // gridRef.current.selectRows([0]);
        onSelect(selectedrecords);
      }
    }
  };
  const rowDeselected = () => {
    if (gridRef?.current) {
      /** Get the selected records. */
      const selectedrecords = gridRef.current.getSelectedRecords();
      // setSelected(selectedrecords);
      if (onSelect) {
        console.log('selectedrecords', selectedrecords);
        onSelect(selectedrecords);
      }
    }
  };
  // const handleSelect = () => {
  //   if (gridRef?.current) {
  //     onSelect &&
  //       onSelect(gridRef?.current.getSelectedRecords()?.[0] as Item);
  //   }
  // };

  return {
    comment,
    setComment,
    contextAnchor,
    setContextAnchor,
    contextItem,
    setContextItem,
    dateFormat,
    dataStateChange,
    getDataSource,
    gridRef,
    rowSelected,
    key,
    rowDeselected,
  };
};

export default useItemsGrid;
