import { useEffect, useState } from 'react';
import { getImage } from '../../../util/data-utils';
import { Chip } from '../../chip/Chip';
import { ImageWithFallback } from '../components/ImageWithFallback';

export const BusinessTypeTemplate = ({
  title,
  information,
  tags,
  imageId,
}: {
  title: string;
  tags?: string[];
  information?: string;
  imageId?: string;
}) => {
  const [businessImage, setBusinessImage] = useState<string>();
  //TODO: replace this with RTKQuery API call so that it is cached.
  useEffect(() => {
    (async () => {
      setBusinessImage(await getImage(imageId));
    })();
  }, []);
  return (
    <Chip
      title={title}
      icon={<ImageWithFallback src={businessImage} />}
      border={false}
    />
  );
};
