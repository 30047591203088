import { styled } from '@mui/material';
import { useIcon } from '../../hooks';
import { ItemBusinessClass } from '../../types';
import { RequestStatusChip } from './RequestStatusChip';

export type PerspectiveClassChipProps = {
  businessClass: ItemBusinessClass;
};

const StyledImage = styled('img')``;

export const PerspectiveClassChip = ({
  businessClass,
}: PerspectiveClassChipProps) => {
  const imageId = businessClass?._ImageId;
  const typeDefId = businessClass?.TypeDefId;

  const { iconDetails } = useIcon({
    imageId,
    typeDefId,
  });

  return (
    <RequestStatusChip
      title={businessClass?.Name || businessClass?._Display}
      icon={
        iconDetails ? (
          <StyledImage
            src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
            alt={iconDetails?.AltText}
            style={{ height: '1.5rem', width: '1.5rem' }}
          />
        ) : null
      }
      subTitle={businessClass?.FullPath ? businessClass?.FullPath : undefined}
      style={{ textAlign: 'left', width: 'fit-content' }}
    />
  );
};
