import { Box, BoxProps } from '../../Box';
import { CommentText, CommentTextProps } from '../../CommentText';

export type CommentTemplateProps = CommentTextProps & Omit<BoxProps, 'onClick'>;

export const CommentTemplate = ({
  comment,
  variant = 'body1',
  numberOfLines = 4,
  isURL,
  onClick,
  ...boxprops
}: CommentTemplateProps) => {
  return (
    <Box
      background='none'
      style={{
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
      }}
      {...boxprops}
    >
      <CommentText
        comment={comment}
        variant={variant}
        numberOfLines={numberOfLines}
        isURL={isURL}
        onClick={onClick}
      />
    </Box>
  );
};
