import { CircleSmall20Filled } from '@fluentui/react-icons';
import { Typography, useTheme } from '@mui/material';
import { MouseEvent } from 'react';
import { Box } from '../../Box';
import { LinkWithArrow } from '../components';

export const SummaryTemplate = ({
  title,
  subTitle,
  information,
  isUnread,
  onClick,
  onContextMenu,
}: {
  title: string;
  subTitle?: string;
  information?: string;
  isUnread?: boolean;
  onClick?: () => void;
  onContextMenu?: (e: MouseEvent) => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      direction='column'
      gap='xsmall'
      background='none'
      style={{ marginLeft: '-0.75rem' }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Box
        direction='row'
        background='none'
        alignItems='center'
        // added styling for temporary removal of the Circle Icon below
        // TODO: remove style if we need to remove the comment for read / unread indicator
        style={{ paddingLeft: '1.25rem' }}
      >
        {/* TODO: remove comments if we need the read / unread indicator */}
        {/* Temporary Removal - Marking requests as read and unread using the button */}
        {/* https://encompaas.visualstudio.com/EncompaaS/_workitems/edit/58122 */}
        {/* <CircleSmall20Filled
          color={isUnread ? theme.palette.primary.main : 'transparent'}
        /> */}
        <LinkWithArrow
          title={title}
          onClick={onClick}
          onContextMenu={onContextMenu}
        />
      </Box>
      <Box background='none' style={{ paddingLeft: '1.25rem' }}>
        <Typography variant='body2' noWrap>
          {subTitle}
        </Typography>
        <Typography
          variant='body2'
          noWrap
          sx={
            subTitle && {
              marginTop: '0.5rem',
            }
          }
        >
          {information}
        </Typography>
      </Box>
    </Box>
  );
};
