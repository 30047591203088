import {
  ChevronDown24Regular,
  ChevronUp24Regular,
} from '@fluentui/react-icons';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Fragment, useEffect, useState } from 'react';
import { Box } from './Box';
import { Button, CollapseButton, RefreshButton } from './button';

export type SideMenuItem = {
  icon?: JSX.Element;
  title?: string;
  count?: number;
  value?: number | string;
  caption?: string;
};
export type SideMenuItemGroup = {
  title?: string;
  items?: SideMenuItem[];
};

export interface SideMenuProps {
  collapsed?: boolean;
  collapsedSize?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  selected?: SideMenuItem;
  width?: number | string;
  items?: SideMenuItem[] | SideMenuItemGroup[];
  refreshIndicator?: boolean;
  blur?: boolean;
  shadow?: boolean;
  groupState?: boolean[];
  canCollapseGroups?: boolean;
  onCollapse?: (collapsed: boolean) => void;
  onRefresh?: () => void;
  onSelect?: (item: SideMenuItem) => void;
  onGroupCollapse?: (index: number, collapsed: boolean) => void;
}

export const SideMenu = ({
  width,
  collapsed,
  collapsedSize = 'medium',
  selected,
  items,
  refreshIndicator,
  blur,
  shadow,
  groupState,
  canCollapseGroups,
  onCollapse,
  onRefresh,
  onSelect,
  onGroupCollapse,
}: SideMenuProps) => {
  const theme = useTheme();

  //selected can be controlled by parent
  const [_selected, setSelected] = useState<SideMenuItem | undefined>(
    //need to allow for empty items
    'items' in (items?.[0] ?? {})
      ? (items?.[0] as SideMenuItemGroup)?.items?.[0]
      : items?.[0]
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [_itemGroups, setItemGroups] = useState<SideMenuItemGroup[]>([]);
  const [_groupState, setGroupState] = useState<boolean[]>(
    //need to allow for empty items
    new Array('items' in (items?.[0] ?? {}) ? items?.length ?? 1 : 1).fill(
      false
    )
  );

  //collapsed state can be controlled by parent
  const [_collapsed, setCollapsed] = useState(false);
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const [cssClass, setCssClass] = useState('');

  const handleSelect = (item: SideMenuItem) => {
    setSelected(item);
    onSelect && onSelect(item);
  };
  const handleCollapse = (c: boolean) => {
    setCollapsed(c);
    onCollapse && onCollapse(c);
  };
  const handleGroupCollapse = (index: number) => {
    if (canCollapseGroups) {
      const _state = [..._groupState];
      _state[index] = !(_state[index] ?? true);
      onGroupCollapse && onGroupCollapse(index, _state[index] ?? false);
      setGroupState(_state);
    }
  };

  useEffect(() => {
    //set the selectedIndex
    let itemIndex = -1;
    const groupIndex = _itemGroups?.findIndex((g: SideMenuItemGroup) => {
      itemIndex = g.items?.findIndex(
        (i: SideMenuItem) => i.value === (selected?.value ?? _selected?.value)
      );
      return itemIndex > -1;
    });
    setSelectedIndex(itemIndex > -1 ? groupIndex * 100 + itemIndex : -1);
  }, [selected, _selected, _itemGroups]);

  useEffect(() => {
    setMenuCollapsed(collapsed ?? _collapsed);
    let _cssClass = `ecs-sidemenu`;
    if (collapsed ?? _collapsed)
      _cssClass += ` ecs-sidemenu-collapsed ecs-sidemenu-collapsed-${collapsedSize}`;
    setCssClass(_cssClass);
  }, [collapsed, _collapsed]);

  useEffect(() => {
    //can't use a straight index in groups
    if ('items' in (items?.[0] ?? {})) {
      // item groups
      setItemGroups(items);
    } else {
      //items
      setItemGroups([{ items }]);
    }
  }, [items]);

  useEffect(() => {
    setGroupState(
      groupState ?? new Array(_itemGroups?.length ?? 1).fill(false)
    );
  }, [groupState, _itemGroups]);

  return (
    <Box
      width={menuCollapsed ? undefined : width ?? 20}
      direction='column'
      background='light'
      rounded='top'
      blur={blur}
      shadow={shadow}
      className={cssClass}
    >
      <Box
        background='none'
        gap='none'
        padding='small'
        justifyContent='start'
        alignItems='stretch'
      >
        {_itemGroups?.map((itemGroup, groupindex) => (
          <Fragment key={groupindex}>
            {itemGroup.title && (
              <Button
                color='info'
                shape={menuCollapsed ? 'square' : 'default'}
                variant='text'
                size='large'
                disabled={!canCollapseGroups}
                tooltip={
                  menuCollapsed ? (
                    <Typography variant='h4' sx={{ color: 'inherit' }}>
                      {itemGroup.title}
                    </Typography>
                  ) : undefined
                }
                tooltipPlacement='right'
                style={{
                  marginTop: groupindex === 0 ? '1rem' : '1.5rem',
                  marginBottom: '0.125rem',
                  gap: 0,
                  justifyContent: menuCollapsed ? 'center' : 'space-between',
                  ...(!menuCollapsed && {
                    paddingLeft: '1.35rem',
                    paddingRight: '1.5rem',
                  }),
                  ...(menuCollapsed && { padding: 0 }),
                }}
                endIcon={
                  _groupState[groupindex] ? (
                    <ChevronUp24Regular color={theme.palette.info.main} />
                  ) : (
                    <ChevronDown24Regular color={theme.palette.info.main} />
                  )
                }
                onClick={() =>
                  canCollapseGroups && handleGroupCollapse(groupindex)
                }
              >
                {!menuCollapsed && (
                  <Typography variant='h3'>{itemGroup.title}</Typography>
                )}
              </Button>
            )}
            {!_groupState[groupindex] &&
              itemGroup.items?.map((item, index) => (
                <SideMenuItemButton
                  key={index}
                  item={item}
                  selected={groupindex * 100 + index === selectedIndex}
                  showDivider={
                    !itemGroup.title &&
                    index !== selectedIndex &&
                    index !== selectedIndex - 1 &&
                    index !== items.length - 1
                  }
                  collapsed={menuCollapsed}
                  size={itemGroup.title ? 'small' : 'large'}
                  onClick={(item: SideMenuItem) => handleSelect(item)}
                />
              ))}
          </Fragment>
        ))}
      </Box>
      <Box
        background='none'
        gap='small'
        justifyContent='end'
        alignItems={menuCollapsed ? 'center' : 'start'}
        style={{ flex: 0, overflow: 'unset' }}
      >
        {onRefresh && (
          <RefreshButton
            size={collapsedSize}
            indicator={refreshIndicator}
            onClick={() => onRefresh()}
          />
        )}
        {onRefresh && onCollapse && <SideMenuDivider size={collapsedSize} />}
        {onCollapse && (
          <CollapseButton
            size={collapsedSize}
            direction={menuCollapsed ? 'right' : 'left'}
            onClick={() => handleCollapse(!menuCollapsed)}
          />
        )}
      </Box>
    </Box>
  );
};

const SideMenuItemButton = ({
  item,
  selected = false,
  showDivider = false,
  collapsed = false,
  size = 'small',
  onClick,
}: {
  item: SideMenuItem;
  selected?: boolean;
  showDivider?: boolean;
  collapsed?: boolean;
  size?: 'small' | 'large';
  onClick?: (item: SideMenuItem) => void;
}) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Button
        startIcon={item.icon}
        onClick={() => onClick && onClick(item)}
        color='info'
        variant='text'
        size='large'
        shape={collapsed ? 'square' : 'default'}
        tooltip={
          collapsed &&
          `${item.title}${
            item.count !== undefined ? ' (' + item.count + ')' : ''
          }`
        }
        tooltipPlacement='right'
        //todo: refine the following values for other menu sizes
        sx={{
          height: size === 'large' ? '6.25rem' : '4rem',
          justifyContent: collapsed ? 'center' : 'start',
          gap: collapsed ? 0 : '0.875rem',
          ...(collapsed && { padding: 0 }),
          ...(!collapsed && {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }),
          ...(selected && {
            color: theme.palette.info.dark,
            backgroundColor: theme.palette.background.paper,
          }),
        }}
      >
        {!collapsed && (
          <Box background='none' direction='row' justifyContent='space-between'>
            <Typography variant='h4'>{item.title}</Typography>
            {item.count !== undefined && (
              <Typography
                variant='h4'
                sx={{
                  color: theme.palette.info[600],
                }}
              >
                {item.count}
              </Typography>
            )}
          </Box>
        )}
      </Button>
      {showDivider && <SideMenuDivider size='full' />}
    </Fragment>
  );
};

const SideMenuDivider = ({
  size = 'medium',
}: {
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'full';
}) => {
  return (
    <div className={`ecs-sidemenu-divider ecs-sidemenu-divider-${size}`}>
      <div />
    </div>
  );
};
