import { memo } from 'react';
import { Box } from '../../../Box';

import {
  TreeGridProperties,
  BusinessTypePropDefsValue,
  ItemBusinessObject,
} from '../../../../types';

import { useCustomTypeActions } from '../../../../hooks/useCustomTypeActions';
import { MemoizedCustomTypeTreeGrid } from '../../../CustomTypeTreeGrid';
import { OnChangeValue } from '../../EditCustomTypeDialog';

export type CustomTypeFieldProps = {
  onChange: (value: OnChangeValue) => void;
  propertyValue?: string;
  businessObject: ItemBusinessObject;
  treeGridProperties: TreeGridProperties;
  propertyDetails: BusinessTypePropDefsValue;
};

export const CustomTypeField = memo(
  ({
    onChange,
    propertyValue,
    businessObject,
    propertyDetails,
    treeGridProperties,
  }: CustomTypeFieldProps) => {
    const {
      treeGridData,
      isAllowAdding,
      isAllowEditing,
      isAllowDeleting,
      selectedRow,
      handleAdd,
      handleDelete,
      handleRowSelected,
      handleRowDeselected,
      handleActionComplete,
    } = useCustomTypeActions({
      onChange,
      propertyValue,
      businessObject,
      propertyDetails,
      treeGridProperties,
    });

    return (
      <Box
        padding='large'
        background='none'
        height='100%'
        style={{
          padding: 0,
        }}
      >
        <MemoizedCustomTypeTreeGrid
          handleAdd={handleAdd}
          selectedRow={selectedRow}
          handleDelete={handleDelete}
          treeGridData={treeGridData}
          isAllowAdding={isAllowAdding}
          isAllowEditing={isAllowEditing}
          rowSelected={handleRowSelected}
          isAllowDeleting={isAllowDeleting}
          rowDeselected={handleRowDeselected}
          handleActionComplete={handleActionComplete}
        />
      </Box>
    );
  }
);
