import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../auth';
import {
  DisposalDetailsCollection,
  DisposalDetailsCountCollection,
} from '../types';

import { config } from '../config';

const ENDPOINT = 'DisposalDetails';

export const disposalDetailsApi = createApi({
  reducerPath: 'disposalDetailsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ['DisposalDetails'],
  endpoints: (builder) => ({
    getDisposalRequestActioningRequired: builder.query<
      DisposalDetailsCountCollection,
      { requestId: string }
    >({
      query: ({ requestId }) => {
        return {
          //TODO: Refactor this query to use DisposalRequestId_ActioningRequired
          url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (ActioningRequired eq true)&$top=1&$count=true`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalDetails'],
    }),
    getDisposalRequestRejectedInOtherRequest: builder.query<
      DisposalDetailsCountCollection,
      { requestId: string }
    >({
      query: ({ requestId }) => {
        return {
          //TODO: Refactor this query to use DisposalRequestId_RejectedInOtherRequest
          url: `${ENDPOINT}?$filter=DisposalRequestId eq '${requestId}' and (RejectedInOtherRequest eq true)&$top=1&$count=true`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalDetails'],
    }),
    getItemExceptionDisposalDetails: builder.query<
      DisposalDetailsCollection,
      { itemId: string }
    >({
      query: ({ itemId }) => {
        return {
          //TODO: Refactor this query to use DisposalRequestId_RejectedInOtherRequest
          url: `${ENDPOINT}?$filter=ItemId eq '${itemId}'&$select=ActioningRequired,RejectedInOtherRequest,RelatedItemActioningRequired,RelatedItemRejectedInOtherRequest&$top=1`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalDetails'],
    }),
  }),
});

export const {
  useGetDisposalRequestActioningRequiredQuery,
  useGetDisposalRequestRejectedInOtherRequestQuery,
  useGetItemExceptionDisposalDetailsQuery
} = disposalDetailsApi;
