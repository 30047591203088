import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { CalendarLtr20Regular } from '@fluentui/react-icons';
import { useTheme } from '@mui/material';

export const DateField = ({ value, onChange }) => {
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          width: '100%',
          maxWidth: '21.75rem',
          height: '3rem',
          '& .MuiButtonBase-root': {
            color: theme.palette.primary.main,
          },
        }}
        slots={{
          openPickerIcon: CalendarLtr20Regular,
        }}
        value={value}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};
