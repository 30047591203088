import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { MouseEvent, useEffect, useState } from 'react';
import { getExceptionLabel, getImage } from '../../../util/data-utils';
import { Box } from '../../Box';
import { Indicator } from '../../chip';
import { ImageWithFallback } from '../components/ImageWithFallback';
import { LinkWithArrow } from '../components/LinkWithArrow';
import { useItemDisposalDetailsExceptions } from '../../../hooks';
import { convertObjectToArray } from '../../../util';
export const ItemSummaryTemplate = ({
  title,
  subTitle,
  information,
  imageId,
  itemId,
  tags,
  onClick,
  onClickItems,
  onContextMenu,
  count,
  type,
}: {
  title: string;
  subTitle?: string;
  information?: string;
  tags?: string[];
  onClick?: () => void;
  onClickItems?: () => void;
  imageId?: string;
  itemId?: string;
  onContextMenu?: (e: MouseEvent) => void;
  count?: number;
  type?: 'item' | 'attachment';
}) => {
  const [businessImage, setBusinessImage] = useState('');
  //TODO: replace getImage with RTKQuery API call so that it is cached
  useEffect(() => {
    (async () => {
      setBusinessImage(await getImage(imageId));
    })();
  }, []);

  const {
    data: disposalDetailsExceptions,
  } = useItemDisposalDetailsExceptions({ id:itemId});

  const exceptions = disposalDetailsExceptions != null && convertObjectToArray(disposalDetailsExceptions).filter(x => x[1] === true)

  return (
    <Box direction='column' gap='small' background='none'>
      <Box
        alignItems='center'
        direction='row'
        gap='small'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
        style={{ marginLeft: '1rem' }}
      >
        <ImageWithFallback src={businessImage} sx={{ mr: '0.6rem' }} />
        <LinkWithArrow
          title={title}
          onClick={onClick}
          onContextMenu={onContextMenu}
        />
      </Box>
      <Box
        alignItems='center'
        direction='row'
        background='none'
        onContextMenu={(e) => e.preventDefault()}
        style={{ marginLeft: '1rem', textOverflow: 'ellipsis' }}
      >
        {/* TODO: should this icon be here? it has opacity 0 */}
        <DocumentHeaderFooter24Filled
          opacity={0}
          style={{
            marginRight: '0.6rem',
            minWidth: '2rem',
            alignSelf: 'flex-start',
            marginTop: '2px',
          }}
        />
        <Box
          direction='column'
          background='none'
          gap={'medium'}
          onContextMenu={(e) => e.preventDefault()}
        >
          {count > 0 && (
            <LinkWithArrow
              title={`${count} ${type == 'item' ? 'member' : ''} ${type}${
                count > 1 ? 's' : ''
              }`}
              onClick={onClickItems}
              onContextMenu={onContextMenu}
              variant={'body2'}
            />
          )}
          <Box
            gap='small'
            background='none'
            direction='row'
            style={{
              textOverflow: 'ellipsis',
              flexWrap: 'wrap',
              marginRight: '1',
            }}
          >
            {!!exceptions && exceptions.length > 0 ? exceptions.map((x, i) => {
                return (
                  <Indicator
                    key={i}
                    label={getExceptionLabel(x[0])}
                    color='warning'
                  />
                )
              }) : !!tags && tags.map((tag, i) => <Indicator label={tag} key={i} />)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
