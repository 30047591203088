import { Box } from './Box';
import { Typography } from '@mui/material';
import { EditButton } from './button';
import { useTooltip } from '../hooks/useTooltip';
import { Tooltip } from './Tooltip';

export const DetailsRow = ({
  label,
  data,
  alignData = false,
  size = 'large',
  onEdit,
}: {
  label: string;
  data?: string | React.ReactNode;
  alignData?: boolean;
  size?: 'small' | 'large';
  onEdit?: () => void;
}) => {
  const labelWidth = size === 'small' ? '8rem' : '14rem';

  const { dataRef: labelRef, title: tooltipTitleForLabel } = useTooltip(label);

  const { dataRef, title: tooltipTitleForData } = useTooltip(data);

  return (
    <Box
      background='none'
      direction='row'
      justifyContent='start'
      gap='small'
      style={{
        overflow: 'visible',
        margin: '1.25rem 0',
        minHeight: '2.5rem',
      }}
    >
      <Tooltip title={tooltipTitleForLabel} arrow>
        <Typography
          variant='h5'
          ref={labelRef}
          sx={{
            width: labelWidth,
            minWidth: labelWidth,
            maxWidth: labelWidth,
            whiteSpace: 'initial',
            wordBreak: 'break-word',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
          }}
        >
          {label}
        </Typography>
      </Tooltip>
      <Box
        alignItems='start'
        background='none'
        style={{
          marginTop: alignData ? '-0.375rem' : 0,
          overflow: 'hidden',
        }}
      >
        {typeof data === 'string' ? (
          <Tooltip title={tooltipTitleForData} arrow>
            <Typography
              variant='body2'
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'initial',
                wordBreak: 'break-word',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
              }}
              ref={dataRef}
            >
              {data}
            </Typography>
          </Tooltip>
        ) : (
          data
        )}
      </Box>
      <Box
        background='none'
        style={{
          marginTop: '-0.5625rem',
        }}
        shrink
      >
        {!!onEdit && <EditButton onClick={onEdit} />}
      </Box>
    </Box>
  );
};
