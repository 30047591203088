import { RetentionClass } from '../../../types';
import { Box } from '../../Box';
import { RetentionClassChip } from '../../chip';

export const RetentionClassTemplate = ({
  classifications,
}: {
  classifications?: RetentionClass[];
}) => {
  return (
    <Box background='none' alignItems='start'>
      {(classifications ?? []).map((rClass, i) => (
        <RetentionClassChip classification={rClass} key={i} border={false} />
      ))}
    </Box>
  );
};
