import {
  useAppDispatch,
  setCurrentItem,
  useAppSelector,
} from '@encompaas/common/store';
import {
  Box,
  ChangeApproversDialog,
  ConfirmDialog,
  DisposalApprovalDialog,
  DisposalRequestDetailsPanel,
  DisposalRequestHeaderActionButtons,
  Alert,
  DisposalRequestRemovalDialog,
  DisposalRequestSuccessAlert,
  DisposalRequestWarningAlert,
  Filter,
  FullModalDialog,
  HomeButton,
  Indicator,
  ItemPanel,
  ItemsGrid,
  OpenInContextMenuTarget,
  TabPanel,
} from '@encompaas/common/components';
import { ALERT_TEXTS, TEST_CONSTANTS } from '@encompaas/common/constants';
import {
  useApiAlert,
  useDisposalRequest,
  useUser,
  useDisposalDetailsExceptions,
  useItemGridFilterCount,
} from '@encompaas/common/hooks';
import { useRemoveItemsFromDisposalRequestMutation } from '@encompaas/common/services';
import { CloseRequest24, DisposalRequest64 } from '@encompaas/common/icons';
import {
  useGetApproversListQuery,
  useGetRequestItemsQuery,
  useUpdateDisposalRequestApproversMutation,
  useUpdateDisposalRequestMutation,
  useStartDisposalJobMutation,
  requestApi,
} from '@encompaas/common/services';
import { ArrowLeft24Regular, Delete24Regular } from '@fluentui/react-icons';
import {
  Button,
  Divider,
  Grow,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';

import {
  Approver,
  DisposalRequest,
  DisposalRequestResponseStatus,
  DisposalRequestStatus,
  DisposalRequestStatusCaption,
  Item,
  ItemGridQuery,
} from '@encompaas/common/types';
import {
  ArrowExit20Filled,
  MoreVertical20Regular,
  PanelRight20Regular,
} from '@fluentui/react-icons';
import { convertObjectToArray, getExceptionLabel } from '@encompaas/common';

const DisposalRequestPage = () => {
  // Navigational and Theme Hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  // User Information
  const user = useUser();

  //refs
  const alertShownRef = useRef(false);
  const userApiErrorAlertShownRef = useRef(false);
  const gridRef = useRef(null);

  // Search Parameters
  const [queryParams, setQueryParams] = useSearchParams();

  // UI State
  const [selectedTab, setSelectedTab] = useState('details');
  const [selectedItemGridFilter, setSelectedItemGridFilter] =
    useState('All Items');

  // Context Menu State
  const [contextAnchor, setContextAnchor] = useState<Element | null>(null);

  // Item Specific
  const [itemID, setItemID] = useState<string | undefined>();
  const [selectedCheckbox, setSelectedCheckbox] = useState<any[]>([]);

  const [removeItemsFromDisposalRequest, { isLoading }] =
    useRemoveItemsFromDisposalRequestMutation();
  const { all, allExceptions, actioningRequired, rejectedInOtherRequest } =
    useItemGridFilterCount({ disposalId: id! });

  // Alert Hooks
  const { errorAlert, successAlert } = useApiAlert();

  const [destroySuccessAlertOpen, setDestroySuccessAlertOpen] =
    useState<boolean>(false);

  // Mutation Hooks
  const [disposalRequestApprove] = useUpdateDisposalRequestMutation();

  const levels = useAppSelector(
    (state: any) => state.itemModal.navigation.levels
  );
  const [startDisposalJob, result] = useStartDisposalJobMutation();

  // Dialog States
  const [disposalApprovalDialogOpen, setDisposalApprovalDialogOpen] =
    useState(false);
  const [itemRemovalDialogOpen, setItemRemovalDialogOpen] = useState(false);
  const [changeApproversDialogOpen, setChangeApproversDialogOpen] =
    useState(false);
  const [disposalRepproveDialogOpen, setDisposalRepproveDialogOpen] =
    useState(false);
  const [destroyCofirmDialogOpen, setDestroyConfirmDialogOpen] =
    useState(false);
  const [closeRequestCofirmDialogOpen, setCloseRequestDialogOpen] =
    useState(false);
  const [tabIntent, setTabIntent] = useState<'items' | undefined>(undefined);

  enum ItemGridFilter {
    All = 'All Items',
    AllExceptions = 'All Approval Exceptions',
    ActioningRequired = 'Additional Actioning Required',
    RejectedInOtherRequest = 'Rejected in other Requests',
  }

  // Data for story selection
  const args = {
    labelId: 'label-story',
    id: 'select-story',
    defaultValue: {
      value: ItemGridFilter.All,
      display: ItemGridFilter.All,
      count: all?.count ?? 0,
    },
    data: [
      {
        value: ItemGridFilter.AllExceptions,
        display: ItemGridFilter.AllExceptions,
        count: allExceptions?.count ?? 0,
      },
      {
        value: ItemGridFilter.ActioningRequired,
        display: ItemGridFilter.ActioningRequired,
        count: actioningRequired?.count ?? 0,
      },
      {
        value: ItemGridFilter.RejectedInOtherRequest,
        display: ItemGridFilter.RejectedInOtherRequest,
        count: rejectedInOtherRequest?.count ?? 0,
      },
    ],
  };

  const setFilterQuery = (selectedItemGridFilter: string) => {
    switch (selectedItemGridFilter) {
      case ItemGridFilter.AllExceptions:
        return 'DisposalDetails/Any(dd: dd/ ActioningRequired eq true) or DisposalDetails/Any(dd: dd/ RejectedInOtherRequest eq true)';
      case ItemGridFilter.ActioningRequired:
        return 'DisposalDetails/Any(dd: dd/ActioningRequired eq true)';
      case ItemGridFilter.RejectedInOtherRequest:
        return 'DisposalDetails/Any(dd: dd/RejectedInOtherRequest eq true)';
      case ItemGridFilter.All:
      default:
        return undefined;
    }
  };

  // Data Fetching Hooks
  const {
    data: disposalRequest,
    isSuccess: disposalIsSuccess,
    isError: disposalRequestError,
  } = useDisposalRequest({ id, userApiError: user?.isUserApiError });

  const { data: disposalDetailsExceptions } = useDisposalDetailsExceptions({
    id,
    userApiError: user?.isUserApiError,
  });

  useEffect(() => {
    const _selectedTab = queryParams.get('tab') ?? 'details';
    if (_selectedTab !== selectedTab) {
      setSelectedTab(_selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  //Force render
  useEffect(() => {
    !!gridRef.current && (gridRef.current as any).refresh();
  }, [selectedItemGridFilter]);

  useEffect(() => {
    if (disposalRequestError) {
      if (!alertShownRef.current) {
        errorAlert.setError(
          'Response submission failure',
          'Your response submission failed. Please try again, or contact your Administrator.'
        );
        alertShownRef.current = true;
      }
    } else {
      alertShownRef.current = false;
    }
  }, [disposalRequestError, errorAlert]);

  useEffect(() => {
    if (user?.isUserApiError) {
      if (!userApiErrorAlertShownRef.current) {
        errorAlert.setError(
          'Cannot open Disposal Request',
          'It may have been deleted or reassigned to other approvers.'
        );
        userApiErrorAlertShownRef.current = true;
      }
    } else {
      userApiErrorAlertShownRef.current = false;
    }
  }, [user?.isUserApiError, errorAlert]);

  const { data: approvers } = useGetApproversListQuery();

  const [changeApprovers] = useUpdateDisposalRequestApproversMutation();

  const handleHome = () => {
    //if history param is set then we can go back, otherwise go home
    !!queryParams.get('history') ? navigate(-1) : navigate('/');
  };

  const handleSelectTab = (tab: string) => {
    //this will reset the history param so that the home button will go home rather than back
    setQueryParams({ tab });
  };

  const handleDestroyDialogClose = () => {
    setDestroyConfirmDialogOpen(false);
  };
  const handleDestroySuccessAlertOpen = () => {
    setDestroySuccessAlertOpen(true);
  };
  const handleDestroySuccessAlertClose = () => {
    setDestroySuccessAlertOpen(false);
  };
  const handleDestroyItems = () => {
    try {
      startDisposalJob({ requestId: disposalRequest.ID });
    } catch (err) {
      errorAlert.openAlert();
    } finally {
      handleDestroySuccessAlertOpen();
      setDestroyConfirmDialogOpen(false);
    }
  };

  const handleCloseRequestDialogClose = () => {
    setCloseRequestDialogOpen(false);
  };
  const handleCloseRequest = () => {
    try {
      // TODO: actually close the request
    } catch (err) {
      errorAlert.openAlert();
    } finally {
      setCloseRequestDialogOpen(false);
    }
  };

  const handleApproveAction = async (
    comment: string,
    approvers: Approver[]
  ) => {
    setDisposalApprovalDialogOpen(false);
    // TODO: create type for api approversList when update the query
    const approversList: {
      Approver: { ID: string };
      Status: string;
      Order: number;
    }[] = approvers.map((approver, index) => {
      if (index === 0) {
        return {
          Approver: { ID: approver.ID },
          Status: DisposalRequestResponseStatus.Pending,
          Order: index + 1,
        };
      } else {
        return {
          Approver: { ID: approver.ID },
          Status: DisposalRequestResponseStatus.Waiting,
          Order: index + 1,
        };
      }
    });

    try {
      const response = await disposalRequestApprove({
        comment: comment,
        requestId: id,
        approversList: approversList,
        requestedBy: disposalRequest?.RequestedBy?.ID,
      });

      if ('error' in response) {
        const _response = response as {
          error: {
            data: {
              FriendlyMessage: string;
            };
          };
        };
        errorAlert.setError('Error', _response.error.data.FriendlyMessage);
      } else {
        successAlert.setSuccess(
          ALERT_TEXTS.requestApproval.success.title,
          ALERT_TEXTS.requestApproval.success.message
        );
        requestApi.util.invalidateTags(['DisposalRequest']);
      }
    } catch (err) {
      errorAlert.openAlert();
    } finally {
      setCurrentSelectedApprovers([...approvers]);
      setDisposalRepproveDialogOpen(false);
    }
  };

  const handleChangeApproversAction = async (approvers: Approver[]) => {
    if (disposalRequest.Status === DisposalRequestStatus.New) {
      setChangeApproversDialogOpen(false);
      setCurrentSelectedApprovers([...approvers]);
    } else {
      var pendingFound = false;
      const approversList: {
        Approver: { ID: string };
        Status: string;
        Order: number;
      }[] = approvers.map((approver, index) => {
        if (approver.Status === DisposalRequestResponseStatus.Approved) {
          return {
            Approver: { ID: approver.ID },
            Status: DisposalRequestResponseStatus.Approved,
            Order: index + 1,
          };
        } else if (
          approver.Status === DisposalRequestResponseStatus.Delegated
        ) {
          return {
            Approver: { ID: approver.ID },
            Status: DisposalRequestResponseStatus.Delegated,
            Order: index + 1,
          };
        } else {
          let found = pendingFound;
          pendingFound = true;
          return {
            Approver: { ID: approver.ID },
            Status: approver?.Status ?? DisposalRequestResponseStatus.Waiting,
            Order: index + 1,
          };
        }
      });

      try {
        const response = await changeApprovers({
          requestId: id,
          approversList: approversList,
          requestedById: disposalRequest?.RequestedBy?.ID,
        });

        if ('error' in response) {
          const _response = response as {
            error: {
              data: {
                FriendlyMessage: string;
              };
            };
          };
          errorAlert.setError('Error', _response.error.data.FriendlyMessage);
        } else {
          // TODO: add success message
          // successAlert.setSuccess(
          //   ALERT_TEXTS.requestApproval.success.title,
          //   ALERT_TEXTS.requestApproval.success.message
          // );
          requestApi.util.invalidateTags(['DisposalRequest']);
          setCurrentSelectedApprovers([...approvers]);
        }
      } catch (err) {
        errorAlert.openAlert();
      } finally {
        setChangeApproversDialogOpen(false);
        setCurrentSelectedApprovers([...approvers]);
      }
    }
  };

  const handleOpenItems = (item: Item, target?: OpenInContextMenuTarget) => {
    setTabIntent('items');
    setSelectedTab('items');
    handleOpen(item, 'modal');
  };

  const navigateBreadcrumbs = (item: Item) => {
    if (gridRef.current) {
      setItemID(item.ID);
    }
  };

  const handleOpen = (item: Item, target?: OpenInContextMenuTarget) => {
    dispatch(setCurrentItem({ id: item.ID, name: item.DisplayName }));
    const _target = target ?? 'modal';
    switch (_target) {
      case 'new tab':
      case 'new window':
        window.open(
          `/item/${item.ID}?requestId=${id}${
            (disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
            ''
          }`,
          '_blank'
        );
        break;
      case 'chromeless page':
        window.open(`/itempanel/${item.ID}`, '_blank');
        break;
      case 'modal':
        navigateBreadcrumbs(item);
        break;
      case 'new page':
      default:
        navigate(
          `/item/${item.ID}?requestId=${id}${
            (disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
            ''
          }`
        );
        break;
    }
  };

  const handleOpenURL = (url?: string) => {
    if (url) window.open(url, '_blank');
  };
  const show = () => {
    if (gridRef?.current) {
      (gridRef.current as any).openColumnChooser(0, 0);
    }
  };

  const handleCheckbox = (item: any) => {
    setSelectedCheckbox(item);
  };

  const handleRemoveAction = async () => {
    await removeItemsFromDisposalRequest({
      disposalId: id ?? '',
      body: {
        itemIdsToRemove: [...selectedCheckbox.map((x) => `${x.ID}`)],
      },
    });
    if (gridRef?.current) {
      (gridRef.current as any).refresh();
    }
    console.log('handleRemoveAction', selectedCheckbox);
    setItemRemovalDialogOpen(false);
  };

  const Grid = useMemo(() => {
    if (disposalRequest) {
      return (
        <ItemsGrid
          onOpen={handleOpen}
          onOpenURL={handleOpenURL}
          onOpenItems={handleOpenItems}
          onSelect={handleCheckbox}
          disposalId={id!}
          adminMode
          selectedRecords={selectedCheckbox}
          persistanceId={`${selectedItemGridFilter}-grid-203`}
          ref={gridRef}
          filter={setFilterQuery(selectedItemGridFilter)}
          itemGridType={
            disposalRequest.Status === DisposalRequestStatus.Complete
              ? ItemGridQuery.RootWithStatus
              : ItemGridQuery.Root
          }
        />
      );
    } else {
      return <></>;
    }
  }, [disposalRequest, selectedItemGridFilter]);

  const [multipleRetentionClasses, setMultipleRetentionClasses] =
    useState<boolean>(false);

  const { data: dataItems, isSuccess: itemsIsSuccess } =
    useGetRequestItemsQuery(
      { requestId: id! },
      {
        skip: !id,
      }
    );

  useEffect(() => {
    dataItems?.value?.forEach((item: any) => {
      item?.DisposalStates?.value?.forEach((ds: any) => {
        if (ds.DisposalStatus === 'AssignedToRequest') {
          // TODO: turned of disabling of button for demo, change parameter in below line to true after demo
          setMultipleRetentionClasses(false);
        }
      });
    });
  }, [dataItems]);

  const [currentSelectedApprovers, setCurrentSelectedApprovers] = useState<
    Approver[]
  >([]);

  useEffect(() => {
    const _currentSelectedApprovers: Approver[] =
      disposalRequest?.ApproverResponses?.value.map(
        (_approverResponse, index) => ({
          _Display: _approverResponse.Approver?.DisplayName!,
          ID: _approverResponse.Approver?.ID!,
          Department: '',
          Status: _approverResponse.Status,
          Order: index + 1,
        })
      ) ?? [];

    setCurrentSelectedApprovers(_currentSelectedApprovers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RequestStatusChip = ({ request }: { request: DisposalRequest }) => {
    const sx = { marginLeft: '1rem' };
    switch (request?.Status) {
      case DisposalRequestStatus.InApproval:
        return (
          <Indicator
            label={DisposalRequestStatusCaption.ApprovalPending}
            sx={sx}
          />
        );
      case DisposalRequestStatus.Rejected:
        return (
          <Indicator
            label={DisposalRequestStatusCaption.Rejected}
            color='warning'
            sx={sx}
          />
        );
      case DisposalRequestStatus.Approved:
        return (
          <Indicator
            label={DisposalRequestStatusCaption.Approved}
            color='primary'
            sx={sx}
          />
        );
      case DisposalRequestStatus.InProgress:
        return (
          <Indicator label={DisposalRequestStatusCaption.InProgress} sx={sx} />
        );
      case DisposalRequestStatus.Complete:
        return (
          <Indicator label={DisposalRequestStatusCaption.Complete} sx={sx} />
        );

      case DisposalRequestStatus.New:
        return <Indicator label={DisposalRequestStatusCaption.New} sx={sx} />;

      case DisposalRequestStatus.Failed:
        return (
          <Indicator color='warning' label='DESTRUCTION FAILURES' sx={sx} />
        );
      default:
        return <></>;
    }
  };

  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.REQUEST_PAGE}>
      <DisposalRequestWarningAlert
        onClose={errorAlert.closeAlert}
        open={errorAlert.isOpen}
        message={errorAlert.message}
      />
      <DisposalRequestSuccessAlert
        onClose={successAlert.closeAlert}
        open={successAlert.isOpen}
      />
      <Alert
        alertType='success'
        title='Disposal Request Destruction in Progress'
        message='Monitor this request to resolve any item destruction failures.'
        open={destroySuccessAlertOpen}
        onClose={handleDestroySuccessAlertClose}
        autoHideDuration={10000}
      />

      {!!disposalRequest && disposalIsSuccess && (
        <>
          <DisposalApprovalDialog
            open={disposalApprovalDialogOpen}
            title='Request Disposal Approval'
            disposalRequest={disposalRequest}
            approvers={approvers}
            currentSelectedApprovers={currentSelectedApprovers}
            onClose={(approvers) => {
              setCurrentSelectedApprovers(approvers ?? []);
              setDisposalApprovalDialogOpen(false);
            }}
            onAction={handleApproveAction}
          />
          <ChangeApproversDialog
            open={changeApproversDialogOpen}
            approvers={approvers}
            currentSelectedApprovers={currentSelectedApprovers}
            disposalRequest={disposalRequest}
            onClose={() => {
              setCurrentSelectedApprovers(currentSelectedApprovers);
              setChangeApproversDialogOpen(false);
            }}
            onAction={handleChangeApproversAction}
          />
          <DisposalApprovalDialog
            open={disposalRepproveDialogOpen}
            title='Request Approval'
            disposalRequest={disposalRequest}
            approvers={approvers}
            currentSelectedApprovers={currentSelectedApprovers}
            onClose={(approvers) => {
              setCurrentSelectedApprovers(approvers ?? []);
              setDisposalRepproveDialogOpen(false);
            }}
            onAction={handleApproveAction}
          />
        </>
      )}

      <DisposalRequestRemovalDialog
        title='Remove from Disposal Request'
        open={itemRemovalDialogOpen}
        onClose={() => {
          setItemRemovalDialogOpen(false);
        }}
        onAction={handleRemoveAction}
      />

      <ConfirmDialog
        title='Destroy Items'
        actionButtonText='Destroy Items'
        closeButtonText='Cancel'
        icon={<Delete24Regular />}
        onClose={handleDestroyDialogClose}
        onAction={handleDestroyItems}
        open={destroyCofirmDialogOpen}
        message={
          <>
            <p>
              Destroying items will delete them from the repository they were
              discovered from. EncompaaS item properties and content will also
              be deleted.
            </p>
            <p>Proceed?</p>
          </>
        }
      />

      <ConfirmDialog
        title='Close Request'
        actionButtonText='Close Request'
        closeButtonText='Cancel'
        icon={<CloseRequest24 />}
        onClose={handleCloseRequestDialogClose}
        onAction={handleCloseRequest}
        open={closeRequestCofirmDialogOpen}
        message={
          <>
            <p>
              Closing this request will return items within it to Triggered
              disposal state, before deleting the request.
            </p>
            <p>Proceed?</p>
          </>
        }
      />

      <FullModalDialog
        open={!!itemID}
        title={disposalRequest?.Name}
        icon={<ArrowLeft24Regular />}
        onAction={(crumb?: { id: string; name: string }) => {
          const lastNavigatedTo = crumb ?? levels.at(0);
          dispatch(
            setCurrentItem({
              id: lastNavigatedTo.id,
              name: lastNavigatedTo.name,
            })
          );
          setItemID(lastNavigatedTo.id);
        }}
        onClose={() => {
          setItemID(undefined);
          setTabIntent(undefined);
          dispatch(setCurrentItem(undefined));
        }}
      >
        {itemID && (
          <ItemPanel
            id={itemID}
            disposalRequest={disposalRequest}
            adminMode={true}
            initialTab={tabIntent}
            setId={setItemID}
          />
        )}
      </FullModalDialog>

      <Box background='light' rounded='top' blur>
        <Box
          background='none'
          height={3.25}
          direction='row'
          justifyContent='start'
          alignItems='center'
          style={{ padding: '0 1.5rem' }}
        >
          <HomeButton
            text='Home'
            onClick={() => handleHome()}
            data-testid={TEST_CONSTANTS.HOME_BUTTON}
          />
        </Box>
        <Divider style={{ width: '100%' }} />
        <Box
          background='none'
          padding='large'
          direction='row'
          gap='large'
          height={4}
        >
          <Box shrink>
            <DisposalRequest64 />
          </Box>
          <Box background='none' justifyContent='space-between' gap='xsmall'>
            <Box
              background='none'
              direction='row'
              alignItems='center'
              gap='medium'
            >
              <Typography variant='h1' noWrap>
                {disposalRequest?.Name ?? 'Request'}
              </Typography>
              {disposalRequest?.Status && (
                <RequestStatusChip request={disposalRequest} />
              )}
              {convertObjectToArray(disposalDetailsExceptions)
                .filter((x) => x[1] === true)
                .map((x) => {
                  return (
                    <Indicator
                      sx={{ marginLeft: '1rem' }}
                      label={getExceptionLabel(x[0])}
                      color='warning'
                    />
                  );
                })}
            </Box>
            <Typography variant='h4'>Disposal request</Typography>
          </Box>

          <DisposalRequestHeaderActionButtons
            status={disposalRequest?.Status as DisposalRequestStatus}
            multipleRetentionClasses={multipleRetentionClasses}
            onChangeApprovers={() => {
              setChangeApproversDialogOpen(true);
            }}
            onCloseRequest={() => {
              setCloseRequestDialogOpen(true);
            }}
            onRequestApproval={() => {
              setDisposalApprovalDialogOpen(true);
            }}
            onDestroyItems={() => {
              setDestroyConfirmDialogOpen(true);
            }}
            onReapprove={() => {
              setDisposalRepproveDialogOpen(true);
            }}
          />
        </Box>

        <TabPanel
          selected={selectedTab}
          onSelect={handleSelectTab}
          inset={'7rem'}
          tabs={[
            {
              title: 'Details',
              value: 'details',
              children: disposalIsSuccess &&
                !!disposalRequest &&
                itemsIsSuccess && (
                  // test
                  <DisposalRequestDetailsPanel
                    request={disposalRequest}
                    adminMode
                    setApprovalErrorMessage={errorAlert.setMessage}
                    setWarningAlertOpen={errorAlert.openAlert}
                    setChangeApproversDialogOpen={setChangeApproversDialogOpen}
                    multipleRetentionClasses={multipleRetentionClasses}
                    currentSelectedApprovers={currentSelectedApprovers}
                    warnings={disposalDetailsExceptions}
                  />
                ),
            },
            {
              title: 'Items',
              value: 'items',
              children: (
                <Box
                  padding='large'
                  background='none'
                  style={{ height: '100%' }}
                >
                  <Box
                    background='none'
                    style={{ paddingTop: '0.6rem', height: '100%' }}
                  >
                    <Box
                      direction='row'
                      alignItems='center'
                      background='none'
                      height={3}
                    >
                      <Filter
                        {...args}
                        selectedState={selectedItemGridFilter}
                        setSelectedState={setSelectedItemGridFilter}
                      />
                      <Box
                        background='none'
                        gap={'small'}
                        direction='row'
                        justifyContent='end'
                      >
                        <Button
                          onClick={() => setItemRemovalDialogOpen(true)}
                          variant='text'
                          startIcon={<ArrowExit20Filled />}
                          disabled={selectedCheckbox.length === 0}
                        >
                          Remove From Request
                        </Button>
                        <Button
                          sx={{ paddingLeft: '1.2rem' }}
                          onClick={(e) => setContextAnchor(e.currentTarget)}
                          variant='text'
                          startIcon={<MoreVertical20Regular />}
                        />
                      </Box>
                      <Menu
                        open={Boolean(contextAnchor)}
                        anchorEl={contextAnchor}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        onClose={() => {
                          setContextAnchor(null);
                        }}
                        TransitionComponent={Grow}
                        MenuListProps={{
                          className: 'MuiMenu-list-withArrow',
                        }}
                      >
                        <MenuItem
                          sx={{
                            height: '2.75rem',
                            width: '12.5rem',
                            '&:hover': {
                              '& p': {
                                color: theme.palette.common.white,
                              },
                            },
                          }}
                          key={0}
                          onClick={() => {
                            show();
                            setContextAnchor(null);
                          }}
                        >
                          <Box width={2} background='none'></Box>
                          <Typography variant='body2'>Change Column</Typography>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            height: '2.75rem',
                            width: '12.5rem',
                            '&:hover': {
                              '& p': {
                                color: theme.palette.common.white,
                              },
                            },
                          }}
                          key={1}
                          onClick={() => {
                            setContextAnchor(null);
                          }}
                        >
                          <Box width={2} background='none'></Box>
                          <Typography variant='body2'>Refresh</Typography>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            height: '2.75rem',
                            width: '12.5rem',
                            '&:hover': {
                              '& p': {
                                color: theme.palette.common.white,
                              },
                              '& div svg': {
                                color: theme.palette.common.white,
                              },
                            },
                          }}
                          key={2}
                          onClick={() => {
                            setContextAnchor(null);
                          }}
                        >
                          <Box width={2} background='none'>
                            <PanelRight20Regular
                              color={theme.palette.primary.main}
                            />
                          </Box>
                          <Typography variant='body2'>
                            Show Preview Pane
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Box
                      background='none'
                      style={{
                        paddingTop: '0.6rem',
                        overflow: 'visible',
                        height: '100%',
                      }}
                    >
                      {Grid}
                    </Box>
                  </Box>
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </PageWithAppBar>
  );
};

export default DisposalRequestPage;
