import styled from '@emotion/styled';
import { memo } from 'react';
import { useIcon } from '../../../../hooks';
import { AttachedItem } from '../../../../types';
import { Chip } from '../../../chip';

const StyledImage = styled('img')``;

export const AttachedItems = memo(({ item }: { item: AttachedItem }) => {
  const { iconDetails } = useIcon({
    imageId: item?._ImageId,
  });

  return (
    <Chip
      icon={
        <StyledImage
          src={`${process.env.REACT_APP_ENC_IMC_URL}${
            iconDetails?.Url ?? 'item.svg'
          }`}
          alt={iconDetails?.AltText}
          style={{ height: '1.5rem' }}
        />
      }
      title={item?.DisplayName}
      sx={{ justifyContent: 'start', marginBottom: '0.5rem' }}
    />
  );
});
