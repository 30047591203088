import { Box } from '../Box';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Button } from '../button';
import { ItemBusinessClass } from '../../types';
import { Edit20Regular } from '@fluentui/react-icons';
import { useGetPerspectiveClassesQuery } from '../../services';
import { OptionMultiSelect } from '../OptionMultiSelect';
import { Type } from '../SortableOptionList';
import { isEqual } from 'lodash';

export type EditPerspectiveClassesDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  fieldLabel: string;
  onClose?: () => void;
  propertyName?: string;
  propertyValue?: ItemBusinessClass[];
  onSave?: (value: ItemBusinessClass[], propertyName?: string) => void;
};

export const EditPerspectiveClassesDialog = ({
  open,
  title,
  onSave,
  onClose,
  fieldLabel,
  propertyName,
  propertyValue,
}: EditPerspectiveClassesDialogProps) => {
  const theme = useTheme();

  const [selectedOptions, setSelectedOptions] = useState<ItemBusinessClass[]>(
    propertyValue ?? []
  );

  const { data } = useGetPerspectiveClassesQuery({});

  const perspectiveClasses = data?.value;

  const getSelectedOptions = (_selectedOptions: ItemBusinessClass[]) => {
    setSelectedOptions(_selectedOptions);
  };

  const disabledSaveButton =
    isEqual(propertyValue, selectedOptions) ||
    selectedOptions === propertyValue;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '47.625rem',
          maxHeight: '42.438rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box background='none'>
          <Typography
            mb={1}
            variant='body1'
            sx={{ color: theme.palette.info.dark }}
          >
            {fieldLabel}
          </Typography>
          <OptionMultiSelect
            groupBy='FullPath'
            type={Type.PERSPECTIVE}
            options={perspectiveClasses}
            propertyValue={propertyValue}
            placeholder='- Add a perspective class -'
            getSelectedOptions={getSelectedOptions}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={disabledSaveButton}
          onClick={() => !!onSave && onSave(selectedOptions, propertyName)}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
