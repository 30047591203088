import { Grow, Menu, MenuItem } from '@mui/material';

export type OpenInContextMenuTarget =
  | 'modal'
  | 'new page'
  | 'new tab'
  | 'new window'
  | 'chromeless page';

/**
 * OpenInContextMenu - context menu for showing open options
 * @param anchor anchor element for showing menu
 * @event onOpen open event handler
 * @event onClose close event handler
 */
export const OpenInContextMenu = ({
  anchor,
  options = ['modal', 'new page', 'new tab', 'new window', 'chromeless page'],
  onOpen,
  onClose,
}: {
  anchor: Element;
  options?: OpenInContextMenuTarget[];
  onOpen?: (target: OpenInContextMenuTarget) => void;
  onClose?: () => void;
}) => (
  <Menu
    open={Boolean(anchor)}
    anchorEl={anchor}
    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    onClose={() => {
      onClose && onClose();
    }}
    TransitionComponent={Grow}
    MenuListProps={{
      className: 'MuiMenu-list-withArrow',
    }}
  >
    {options.map((target, index) => (
      <MenuItem
        key={index}
        onClick={() => {
          onOpen && onOpen(target);
          onClose && onClose();
        }}
      >
        Open in {target}
      </MenuItem>
    ))}
  </Menu>
);
