import {
  Box,
  DisposalRequestGrid,
  OpenInContextMenuTarget,
  SideMenu,
  SideMenuItem,
  SideMenuItemGroup,
} from '@encompaas/common/components';
import {
  DisposalRequest,
  DisposalRequestAction,
  DisposalRequestActionCaption,
  DisposalRequestActionDoingCaption,
  DisposalRequestActionDoneCaption,
  DisposalRequestCount,
  DisposalRequestStatus,
  DisposalRequestStatusCaption,
} from '@encompaas/common/types';
import {
  CheckmarkSquare24Regular,
  Delete24Regular,
  DismissSquare24Regular,
  ErrorCircle24Regular,
  New24Regular,
  PeopleQueue24Regular,
  SpinnerIos20Filled,
} from '@fluentui/react-icons';
import { TabContext, TabPanel } from '@mui/lab';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useUser } from '@encompaas/common/hooks';
import {
  requestApi,
  useGetRequestCountsByStatusQuery,
} from '@encompaas/common/services';
import {
  setSelectedSideMenuItemValue,
  setSideMenuCollapsed,
  setSideMenuGroupCollapsedState,
} from '@encompaas/common/store';
import { useAppDispatch, useAppSelector } from '@encompaas/common/store';

const HomePage = () => {
  const user = useUser();

  const navigate = useNavigate();

  const [key, setKey] = useState(Math.random().toString(36).substring(2, 7));

  const dispatch = useAppDispatch();

  const { data: counts, isLoading } = useGetRequestCountsByStatusQuery(
    { userId: user?.ID ?? '' },
    { skip: !user?.ID }
  );

  const {
    sideMenuCollapsed,
    sideMenuGroupCollapsedState,
    selectedSideMenuItemValue,
  } = useAppSelector((state) => state.application?.ui ?? {});

  const _menuItems: SideMenuItemGroup[] = [
    {
      title: 'Prepare Requests',
      items: [
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.New],
          icon: <New24Regular />,
          value: DisposalRequestStatus.New,
          caption:
            DisposalRequestStatusCaption[DisposalRequestStatus.New] +
            ' Requests',
        },
      ],
    },
    {
      title: 'Request Disposal',
      items: [
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.InApproval],
          icon: <PeopleQueue24Regular />,
          value: DisposalRequestStatus.InApproval,
          caption:
            DisposalRequestStatusCaption[DisposalRequestStatus.InApproval],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.Approved],
          icon: <CheckmarkSquare24Regular />,
          value: DisposalRequestStatus.Approved,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Approved],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.Rejected],
          icon: <DismissSquare24Regular />,
          value: DisposalRequestStatus.Rejected,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Rejected],
        },
      ],
    },
    {
      title: `${
        DisposalRequestActionCaption[DisposalRequestAction.Destroy]
      } Items`,
      items: [
        {
          title:
            DisposalRequestActionDoingCaption[DisposalRequestAction.Destroy],
          icon: <SpinnerIos20Filled />,
          value: DisposalRequestStatus.InProgress,
          caption:
            DisposalRequestStatusCaption[DisposalRequestStatus.InProgress],
        },
        {
          title:
            DisposalRequestActionDoneCaption[DisposalRequestAction.Destroy],
          icon: <Delete24Regular />,
          value: DisposalRequestStatus.Complete,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Complete],
        },
        {
          title: DisposalRequestStatusCaption[DisposalRequestStatus.Failed],
          icon: <ErrorCircle24Regular />,
          value: DisposalRequestStatus.Failed,
          caption: DisposalRequestStatusCaption[DisposalRequestStatus.Failed],
        },
      ],
    },
  ];

  //create a lookup to access the menuitems for counts
  const _menuItemsIndex = {
    [DisposalRequestStatus.New as string]: [0, 0],
    [DisposalRequestStatus.InApproval as string]: [1, 0],
    [DisposalRequestStatus.Approved as string]: [1, 1],
    [DisposalRequestStatus.Rejected as string]: [1, 2],
    [DisposalRequestStatus.InProgress as string]: [2, 0],
    [DisposalRequestStatus.Complete as string]: [2, 1],
    [DisposalRequestStatus.Failed as string]: [2, 2],
  };

  const [selectedSideMenuItem, setSelectedSideMenuItem] = useState<
    SideMenuItem | undefined
  >(_menuItems?.[0]?.items?.[0]);

  const [menuItems, setMenuItems] = useState(_menuItems);

  useEffect(() => {
    //set default state if not already set
    const index =
      _menuItemsIndex[selectedSideMenuItemValue ?? DisposalRequestStatus.New];
    if (index) {
      handleSelect(_menuItems[index[0]]?.items?.[index[1]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSideMenuItemValue]);

  const handleRefresh = () => {
    setKey(Math.random().toString(36).substring(2, 7));
    requestApi.util.invalidateTags(['DisposalRequest']);
    handleResetCount();
  };
  const handleCollapse = (value: boolean) => {
    dispatch(setSideMenuCollapsed(value));
  };
  const handleGroupCollapse = (index: number, collapsed: boolean) => {
    const _groupState = [
      ...(sideMenuGroupCollapsedState ??
        new Array(_menuItems.length).fill(false)),
    ];
    _groupState[index] = collapsed;
    dispatch(setSideMenuGroupCollapsedState(_groupState));
  };
  const handleSelect = (item?: SideMenuItem) => {
    dispatch(
      setSelectedSideMenuItemValue(item?.value ?? DisposalRequestStatus.New)
    );
    setSelectedSideMenuItem(item);
  };

  const handleOpenRequest = (
    request: DisposalRequest,
    target?: OpenInContextMenuTarget
  ) => {
    const _target = target ?? 'new page';
    console.log('handleOpenRequest', request.ID, target, _target);
    switch (_target) {
      case 'new tab':
      case 'new window':
        window.open(`/request/${request.ID}`, '_blank');
        break;
      case 'modal': //modal yet to be implemented for request page
      case 'new page':
      default:
        navigate(`/request/${request.ID}?history=true`);
        break;
    }
  };
  const handleNewRequest = () => {
    console.log('New request');
  };
  const handleSelectRequests = (requests?: DisposalRequest[]) => {
    console.log('Select requests', requests?.length, requests?.[0]?.Name);
  };
  const handleReadRequests = (requests: DisposalRequest[]) => {
    console.log('Read requests', requests?.length, requests?.[0]?.Name);
  };
  const handleRetryRequests = (requests: DisposalRequest[]) => {
    console.log('Retry requests', requests?.length, requests?.[0]?.Name);
  };
  const handleDestroyRequests = (requests: DisposalRequest[]) => {
    console.log('Destroy requests', requests?.length, requests?.[0]?.Name);
  };
  const handleResetCount = () => {
    //reset all counts to 0 first - remove to not show zero counts
    Object.keys(_menuItemsIndex).forEach((status) => {
      const index = _menuItemsIndex[status];
      (_menuItems[index[0]]?.items?.[index[1]] ?? {}).count = 0;
    });
    //--------
    counts?.forEach((c: DisposalRequestCount) => {
      const index = _menuItemsIndex[c.Status];
      if (index) {
        (_menuItems[index[0]]?.items?.[index[1]] ?? {}).count = c.Count;
      }
    });
    setMenuItems([..._menuItems]);
  };

  useEffect(() => {
    if (!isLoading && counts) {
      handleResetCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts, isLoading]);

  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.HOME_PAGE}>
      <Box direction='row' background='none' gap='medium'>
        <SideMenu
          collapsed={sideMenuCollapsed}
          selected={selectedSideMenuItem}
          collapsedSize='xlarge'
          onSelect={handleSelect}
          onRefresh={() => {
            handleRefresh();
          }}
          onCollapse={handleCollapse}
          items={menuItems}
          canCollapseGroups
          groupState={sideMenuGroupCollapsedState}
          onGroupCollapse={handleGroupCollapse}
          blur
        />
        <Box
          background='light'
          padding='large'
          rounded='top'
          blur
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {user?.ID && (
            <TabContext value={selectedSideMenuItem?.value as string}>
              {_menuItems?.map(
                (itemGroup: SideMenuItemGroup, groupindex: number) => (
                  <Fragment key={groupindex}>
                    {itemGroup.items?.map((item, index) => (
                      <TabPanel
                        value={item.value as string}
                        key={item.value as string}
                        sx={{ padding: 0, display: 'flex', overflow: 'hidden' }}
                      >
                        <DisposalRequestGrid
                          user={user}
                          key={key}
                          filter={item.value as DisposalRequestStatus}
                          title={`${selectedSideMenuItem?.caption}`}
                          onOpen={handleOpenRequest}
                          onNew={handleNewRequest}
                          onSelect={handleSelectRequests}
                          onRead={handleReadRequests}
                          onRetry={handleRetryRequests}
                          onDestroy={handleDestroyRequests}
                          adminMode
                        />
                      </TabPanel>
                    ))}
                  </Fragment>
                )
              )}
            </TabContext>
          )}
        </Box>
      </Box>
    </PageWithAppBar>
  );
};

export default HomePage;
