import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../auth';

import {
  BusinessCatalogNode,
  BusinessTypeProperties,
  BusinessTypePropertyValues,
  BusinessTypes,
  CategoryValues,
  Enums,
  Item,
  ItemClassifications,
  ItemIcon,
  ItemTypeProperties,
  PerspectiveClasses,
  TreeGridProperties,
} from '../types';
import { config } from '../config';

const ENDPOINT = 'Item';

export const itemApi = createApi({
  reducerPath: 'itemApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ['ItemDetails', 'BusinessType', 'BusinessTypeProperties', 'PropLinks'],
  endpoints: (builder) => ({
    // QUERIES
    getItemDetailsFullDetails: builder.query<Item, { itemId: string }>({
      query: ({ itemId }) => {
        return {
          url: `${ENDPOINT}('${itemId}')?$properties=FullDetails`,
          method: 'GET',
        };
      },
      providesTags: ['ItemDetails'],
    }),
    getPropLinks: builder.query<Item, { itemType: string, itemId: string }>({
      query: ({ itemType, itemId }) => {
        return {
          //url: `${config.API_BASE_URL}/TypeDef?$filter=Name eq '${itemType}' and App/Name eq 'Enc'&$expand=PropLinks($expand=PropDef($select=*;$filter=IsRefProp eq true))`,
          url: `${config.API_BASE_URL}/${itemType}('${itemId}')?$properties=FullDetails&$meta=true`,
          method: 'GET',
        };
      },
      providesTags: ['PropLinks'],
    }),
    getCollectionItemsCount: builder.query<any, { collectionId: string }>({
      query: ({ collectionId }) => {
        const BASE_URL: string = `${config.API_BASE_URL}/Enc.Item?`;

        return {
          url: `${BASE_URL}$count=true&$top=0&$filter=(MemberOf eq ${collectionId}) or (AttachmentOfId eq ${collectionId})`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
    }),
    getItemDetailsMemberOf: builder.query<Item, { itemId: string }>({
      query: ({ itemId }) => {
        return {
          url: `${ENDPOINT}('${itemId}')/?$expand=MemberOf($select=ID)`,
          method: 'GET',
        };
      },
      providesTags: ['ItemDetails'],
    }),
    getItemDisposalDetails: builder.query<Item, { itemId: string }>({
      query: ({ itemId }) => {
        return {
          url: `${ENDPOINT}('${itemId}')?$expand=DisposalDetails($expand=DisposalRequest)`,
          method: 'GET',
        };
      },
    }),
    getItemDetailsApplicableClassifications: builder.query<
      Item,
      { itemId: string }
    >({
      query: ({ itemId }) => {
        return {
          url: `${ENDPOINT}('${itemId}')/?$expand=ApplicableClassifications($count=true)`,
          method: 'GET',
        };
      },
      providesTags: ['ItemDetails'],
    }),
    getBusinessTypeProperties: builder.query<
      BusinessTypeProperties,
      { businessTypeId: string }
    >({
      query: ({ businessTypeId }) => {
        return {
          url: `${businessTypeId}/GetEditablePropDefs?$properties=FullDetails`,
          method: 'POST',
        };
      },
      providesTags: ['BusinessType'],
    }),
    getBusinessTypePropertyValues: builder.query<
      BusinessTypePropertyValues,
      { businessTypeId: string; businessObjectId: string }
    >({
      query: ({ businessTypeId, businessObjectId }) => {
        return {
          url: `${businessTypeId}('${businessObjectId}')?$properties=FullDetails`,
          method: 'GET',
        };
      },
      providesTags: ['BusinessTypeProperties'],
    }),
    getIcon: builder.query<ItemIcon, { imageId: string }>({
      query: ({ imageId }) => {
        return {
          url: `Enc.Image?$filter=ID eq '${imageId}'`,
          method: 'GET',
        };
      },
    }),
    getIconByTypeDefId: builder.query<ItemIcon, { typeDefId: string }>({
      query: ({ typeDefId }) => {
        return {
          url: `TypeDef/?$filter= ID eq '${typeDefId}'`,
          method: 'GET',
        };
      },
    }),
    getBusinessTypePropertiesPanelValues: builder.query<
      BusinessTypePropertyValues,
      { typeDefId: string; itemId: string }
    >({
      query: ({ typeDefId, itemId }) => {
        return {
          url: `${typeDefId}('${itemId}')?$expand=BusinessObject($properties=SearchRules)`,
          method: 'GET',
        };
      },
      providesTags: ['BusinessTypeProperties'],
    }),
    getItemTypeProperties: builder.query<
      ItemTypeProperties,
      { typeDefId: string; itemId: string }
    >({
      query: ({ typeDefId, itemId }) => {
        return {
          url: `${typeDefId}('${itemId}')?$properties=FullDetails&$meta=true`,
          method: 'GET',
        };
      },
    }),
    getTreeGridData: builder.query<
      TreeGridProperties,
      { businessTypeId: string }
    >({
      query: ({ businessTypeId }) => {
        return {
          url: 'IMC.TreeGridData/GetTreeGridData?$preserverefs=false',
          method: 'POST',
          body: { businessTypeId },
        };
      },
      providesTags: ['BusinessTypeProperties'],
    }),
    getBusinessTypes: builder.query<BusinessTypes, {}>({
      query: () => {
        return {
          url: `TypeDef?$filter=IsBusinessType eq true and Active eq true and Name ne 'BusinessObject'&$top=100&$orderBy=_Display&$expand=Category($select=InternalName)`,
          method: 'GET',
        };
      },
      providesTags: ['BusinessType'],
    }),
    getEnums: builder.query<Enums, {}>({
      query: () => {
        return {
          url: `TypeDef?$top=10000&$filter=TypeKind eq 'Enum'&$select=all&$expand=Members($select=all;$top=1000)`,
          method: 'GET',
        };
      },
    }),
    getPerspectiveClasses: builder.query<PerspectiveClasses, {}>({
      query: () => {
        return {
          url: '/BusinessClass?$filter=Active eq true&$top=1000000&$usesql=true',
          method: 'GET',
        };
      },
    }),
    getClassifications: builder.query<ItemClassifications, {}>({
      query: () => {
        return {
          url: '/Classification?$top=1000000&$usesql=true',
          method: 'GET',
        };
      },
    }),
    getBusinessCatalogNode: builder.query<BusinessCatalogNode, {}>({
      query: () => {
        return {
          url: '/IMC.BusinessCatalogNode/GetCatalogTree?$preserverefs=false',
          method: 'POST',
        };
      },
    }),
    getItemCount: builder.query<any, { disposalId: string }>({
      query: ({ disposalId }) => {
        return {
          url: `${ENDPOINT}?$count=true&$filter=DisposalRequests/Any(dr: dr/ID eq '${disposalId}')&$top=1`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
      providesTags: ['ItemDetails'],
    }),
    getAllExceptionsItemCount: builder.query<any, { disposalId: string }>({
      query: ({ disposalId }) => {
        return {
          url: `${ENDPOINT}?$count=true&$filter=DisposalRequests/Any(dr: dr/ID eq '${disposalId}') and (DisposalDetails/Any(dd: dd/ ActioningRequired eq true) or DisposalDetails/Any(dd: dd/ RejectedInOtherRequest eq true))&$top=1`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
      providesTags: ['ItemDetails'],
    }),
    getActioningRequiredItemCount: builder.query<any, { disposalId: string }>({
      query: ({ disposalId }) => {
        return {
          url: `${ENDPOINT}?$count=true&$filter=DisposalRequests/Any(dr: dr/ID eq '${disposalId}') and DisposalDetails/Any(dd: dd/ActioningRequired eq true)&$top=1`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
      providesTags: ['ItemDetails'],
    }),
    getRejectedInOtherRequestItemCount: builder.query<any, { disposalId: string }>({
      query: ({ disposalId }) => {
        return {
          url: `${ENDPOINT}?$count=true&$filter=DisposalRequests/Any(dr: dr/ID eq '${disposalId}') and DisposalDetails/Any(dd: dd/RejectedInOtherRequest eq true)&$top=1`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
      providesTags: ['ItemDetails'],
    }),
    getItemCategories: builder.query<CategoryValues, {}>({
      query: () => {
        return {
          url: 'Category?ds=5&$top=100&$orderBy=_Display',
          method: 'GET',
        };
      },
    }),
    getEmailCopy: builder.query<any, {}>({
      query: () => {
        return {
          url: `EmailCopy?$select=all&$expand=ParentFolder($select=DisplayName),Mailbox($expand=Owner;$select=all),Email($expand=From;$select=all)&$filter=EmailID eq '33019'&$top=5`,
          method: 'GET',
        };
      },
    }),
    //MUTATIONS
    removeItemsFromDisposalRequest: builder.mutation<any, { disposalId: string, body: object }>({
      query: ({disposalId, body}) => {
        return {
          url: `/DisposalRequest('${disposalId}')/RemoveItems`,
          method: 'POST',
          body: body,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
    }),
    saveItemDetails: builder.mutation({
      query: ({ ID, TypeDefId, formDetails }) => ({
        url: `/${TypeDefId}('${ID}')`,
        method: 'PATCH',
        body: { ...formDetails },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: [
        'ItemDetails',
        'BusinessType',
        'BusinessTypeProperties',
      ],
    }),
  }),
});

export const {
  // QUERIES
  useGetIconQuery,
  useGetEnumsQuery,
  useGetEmailCopyQuery,
  useGetPropLinksQuery,
  useGetItemCountQuery,
  useGetTreeGridDataQuery,
  useGetBusinessTypesQuery,
  useGetItemCategoriesQuery,
  useGetClassificationsQuery,
  useGetIconByTypeDefIdQuery,
  useGetItemTypePropertiesQuery,
  useGetPerspectiveClassesQuery,
  useGetBusinessCatalogNodeQuery,
  useGetItemDetailsMemberOfQuery,
  useGetItemDisposalDetailsQuery,
  useGetCollectionItemsCountQuery,
  useGetItemDetailsFullDetailsQuery,
  useGetBusinessTypePropertiesQuery,
  useGetAllExceptionsItemCountQuery,
  useGetActioningRequiredItemCountQuery,
  useGetBusinessTypePropertyValuesQuery,
  useGetRejectedInOtherRequestItemCountQuery,
  useGetBusinessTypePropertiesPanelValuesQuery,
  useGetItemDetailsApplicableClassificationsQuery,
  // MUTATIONS
  useRemoveItemsFromDisposalRequestMutation,
  useSaveItemDetailsMutation,
} = itemApi;
