import { Link, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import { User } from '../../../types';
import {
  hasDisplayProperty,
  isBusinessType,
  isPerspectiveClass,
  isStringALink,
  hasRetentionClasses,
} from '../ItemPropertiesUtil';
import { BusinessTypeTemplate } from '../../grid/templates/BusinesssTypeTemplate';
import { PerspectiveClassChip } from '../../chip/PerspectiveClassChip';
import { UserChip } from '../../chip/UserChip';
import { useTooltip } from '../../../hooks';
import { Tooltip } from '../../Tooltip';
import { RetentionClassChip } from '../../chip';

export const PropertyValueTemplate = memo(
  ({ itemValue }: { itemValue: any }) => {
    const theme = useTheme();

    const { dataRef, title } = useTooltip(itemValue);

    if (isPerspectiveClass(itemValue))
      return <PerspectiveClassChip businessClass={itemValue} />;
    else if (isBusinessType(itemValue)) {
      return (
        <BusinessTypeTemplate
          title={itemValue._Display}
          information={itemValue.DerivedPath}
          imageId={itemValue._ImageId}
        />
      );
    } else if (hasRetentionClasses(itemValue)) {
      if (Array.isArray(itemValue.value)) {
        return itemValue.value.map((item, index) => (
          <RetentionClassChip
            key={index}
            classification={item}
          />
        ));
      }
    } else if (hasDisplayProperty(itemValue))
      return <UserChip user={{ name: itemValue._Display } as User} />;
    else if (isStringALink(itemValue)) {
      return (
        <Link href={itemValue} target='_blank' sx={{ textDecoration: 'none' }}>
          <Tooltip title={title} arrow>
            <Typography
              ref={dataRef}
              variant='body1'
              sx={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'break-spaces',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                wordBreak: 'break-all',
              }}
            >
              {itemValue}
            </Typography>
          </Tooltip>
        </Link>
      );
    }

    return (
      <Tooltip title={title} arrow>
        <Typography
          ref={dataRef}
          variant='body1'
          sx={{
            textDecoration: 'none',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'break-spaces',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            wordBreak: 'break-all',
          }}
        >
          {itemValue}
        </Typography>
      </Tooltip>
    );
  }
);
