import {
  ArrowClockwiseDashes20Regular,
  CheckmarkSquare20Regular,
  Delete20Regular,
  DismissSquare20Regular,
  ErrorCircle20Regular,
  New20Regular,
  PeopleQueue20Regular,
} from '@fluentui/react-icons';
import {
  DisposalRequestStatus,
  DisposalRequestStatusCaption,
} from '../../types';
import { Chip, ChipProps } from './Chip';

export type DisposalRequestStatusChipProps = {
  status: DisposalRequestStatus;
} & Omit<ChipProps, 'label' | 'icon' | 'color'>;

export const DisposalRequestStatusChip = ({
  status,
  border = false,
  ...props
}: DisposalRequestStatusChipProps) => {
  // can make these global if needed
  const _icon = {
    [DisposalRequestStatus.New]: <New20Regular />,
    [DisposalRequestStatus.InApproval]: <PeopleQueue20Regular />,
    [DisposalRequestStatus.Approved]: <CheckmarkSquare20Regular />,
    [DisposalRequestStatus.Rejected]: <DismissSquare20Regular />,
    [DisposalRequestStatus.InProgress]: <ArrowClockwiseDashes20Regular />,
    [DisposalRequestStatus.Complete]: <Delete20Regular />,
    [DisposalRequestStatus.Failed]: <ErrorCircle20Regular />,
  };

  const _color: Record<DisposalRequestStatus, ChipProps['color']> = {
    [DisposalRequestStatus.New]: 'default',
    [DisposalRequestStatus.InApproval]: 'default',
    [DisposalRequestStatus.Approved]: 'primary',
    [DisposalRequestStatus.Rejected]: 'warning',
    [DisposalRequestStatus.InProgress]: 'default',
    [DisposalRequestStatus.Complete]: 'default',
    [DisposalRequestStatus.Failed]: 'error',
  };

  return (
    <Chip
      {...props}
      border={border}
      title={DisposalRequestStatusCaption[status]}
      icon={_icon[status]}
      color={_color[status]}
      sx={{
        '& > .MuiChip-icon': {
          margin: '0 0.25rem 0 0',
          minWidth: '1.25rem',
        },
      }}
    />
  );
};
