import { Box } from '../Box';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Button } from '../button';
import { RetentionClass } from '../../types';
import { Edit20Regular } from '@fluentui/react-icons';
import { useGetClassificationsQuery } from '../../services';
import { OptionMultiSelect } from '../OptionMultiSelect';
import { Type } from '../SortableOptionList';
import { isEqual } from 'lodash';

export type EditRetentionClassesDialogProps = {
  open: boolean;
  title?: string;
  height?: string;
  fieldLabel: string;
  onClose?: () => void;
  propertyName?: string;
  propertyValue?: RetentionClass[];
  onSave?: (value: RetentionClass[], propertyName?: string) => void;
};

export const EditRetentionClassesDialog = ({
  open,
  title,
  onSave,
  onClose,
  fieldLabel,
  propertyName,
  propertyValue,
}: EditRetentionClassesDialogProps) => {
  const theme = useTheme();

  const [selectedOptions, setSelectedOptions] = useState<RetentionClass[]>(
    propertyValue ?? []
  );

  const { data } = useGetClassificationsQuery({});
  // TODO: Currently there's an error on saving other retention classes with TypeDefId (186)
  // TODO: Update this implementation, temporary fix to exclude retention class that causing errors when saving.
  const retentionClasses = data?.value?.filter((obj) =>
    obj.$type.includes('Encompaas.Enweb.Domain.BusinessTransaction')
  );

  const getSelectedOptions = (_selectedOptions: RetentionClass[]) => {
    setSelectedOptions(_selectedOptions);
  };

  const disabledSaveButton =
    isEqual(propertyValue, selectedOptions) ||
    selectedOptions === propertyValue;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: '47.625rem',
          maxHeight: '42.438rem',
        },
      }}
    >
      <DialogTitle>
        <Edit20Regular />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box background='none'>
          <Typography
            mb={1}
            variant='body1'
            sx={{ color: theme.palette.info.dark }}
          >
            {fieldLabel}
          </Typography>
          <OptionMultiSelect
            type={Type.RETENTION}
            options={retentionClasses}
            propertyValue={propertyValue ?? []}
            placeholder='- Add a retention class -'
            getSelectedOptions={getSelectedOptions}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={disabledSaveButton}
          onClick={() => !!onSave && onSave(selectedOptions, propertyName)}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
