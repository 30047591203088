import { MouseEvent } from 'react';
import { PageEventArgs } from '@syncfusion/ej2-react-grids';
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  Freeze,
  Inject,
  Page,
  Reorder,
  Resize,
  Sort,
  TreeGridComponent,
} from '@syncfusion/ej2-react-treegrid';
import { Item, ItemGridQuery } from '../../types';
import { Box } from '../Box';
import { ItemManagementStatusChip } from '../chip/ItemManagementStatusChip';
import DetailDialog from '../dialog/DetailDialog';
import {
  CommentTemplate,
  ItemSummaryTemplate,
  PagerData,
  PagerTemplate,
  RetentionClassTemplate,
} from './templates';
import useItemsGrid from './useItemsGrid';
import { ColumnChooser } from '@syncfusion/ej2-react-treegrid';
import { BusinessTypeTemplate } from './templates/BusinesssTypeTemplate';
import { DisposalStatusTemplate } from './templates/DisposalStatusTemplate';
import { forwardRef } from 'react';
import {
  OpenInContextMenu,
  OpenInContextMenuTarget,
} from './components/OpenInContextMenu';

export type ItemsGridProps = {
  disposalId?: string;
  data?: Item[];
  onOpen?: (item: Item, target?: OpenInContextMenuTarget) => void;
  onOpenItems?: (item: Item, target?: OpenInContextMenuTarget) => void;
  onOpenURL?: (url: string) => void;
  onSelect?: (request: any) => void;
  key?: string;
  pageSize?: number;
  pageSizes?: number[];
  adminMode?: boolean;
  testId?: string;
  filter?: string;
  selectedRecords?: any[];
  persistanceId?: string;
  itemGridType: ItemGridQuery;
};

export const ItemsGrid = forwardRef(
  (
    {
      disposalId,
      data,
      onOpen,
      onOpenItems,
      onSelect,
      pageSize = 30,
      pageSizes = [30, 60, 100],
      adminMode = false,
      testId = 'ItemsGrid',
      filter,
      selectedRecords,
      persistanceId,
      itemGridType,
      onOpenURL,
    }: ItemsGridProps,
    gridRef: any
  ) => {
    const {
      getDataSource,
      dataStateChange,
      comment,
      setComment,
      contextAnchor,
      setContextAnchor,
      contextItem,
      setContextItem,
      dateFormat,
      key,
      // gridRef,
      rowSelected,
      rowDeselected,
    } = useItemsGrid({
      onSelect,
      pageSize,
      disposalId,
      data,
      adminMode,
      filter,
      itemGridType,
      selectedRecords,
      gridRef,
    });

    return (
      //wrap with a box so that scrolling in the grid works
      <Box background='none'>
        <TreeGridComponent
          enableCollapseAll
          allowReordering={true}
          //@ts-ignore
          dataSource={!!data ? data : []}
          dataBound={!!data ? undefined : getDataSource}
          dataStateChange={!!data ? undefined : dataStateChange}
          loadingIndicator={{ indicatorType: 'Shimmer' }}
          ref={gridRef}
          showColumnChooser={true}
          treeColumnIndex={1}
          allowResizing={true}
          enablePersistence={true}
          parentIdMapping='ParentId'
          rowSelected={rowSelected}
          rowDeselected={rowDeselected}
          allowSorting={true}
          data-testid={testId}
          hasChildMapping='HasMember'
          className='items-grid-tree-grid'
          idMapping='ID'
          height='100%'
          width='100%'
          childMapping={!!data ? 'Items' : undefined}
          actionBegin={(args: PageEventArgs) => {
            console.log(args.requestType);
          }}
          //need the id to maintain persisted data
          id={`${
            !!persistanceId ? persistanceId + itemGridType : 'ItemGridl10'
          }`}
          allowPaging={true}
          pagerTemplate={(pagerData: PagerData) => {
            const pagerDataWithTotalPages = {
              ...pagerData,
              totalPages: Math.ceil(
                pagerData.totalRecordsCount! / pagerData.pageSize!
              ),
            };
            return (
              <PagerTemplate
                key={key}
                pagerData={pagerDataWithTotalPages}
                grid={gridRef?.current}
                pageSizes={pageSizes}
              />
            );
          }}
        >
          <Inject
            services={[
              Resize,
              Reorder,
              Sort,
              Freeze,
              Page,
              ColumnMenu,
              ColumnChooser,
            ]}
          />
          <ColumnsDirective>
            {/* TODO: Add Checkbox for future functionality */}
            <ColumnDirective
              type='checkbox'
              width='40'
              showInColumnChooser={false}
              field='Checkbox'
            />
            <ColumnDirective
              field='DisplayName'
              key='DisplayName'
              headerText='Item'
              clipMode='Ellipsis'
              showCheckbox={false}
              allowResizing={true}
              showInColumnChooser={false}
              width={'5rem'}
              template={(item: Item) => {
                return (
                  <ItemSummaryTemplate
                    title={item.DisplayName}
                    itemId={item.ID}
                    imageId={!!item._ImageId ? item._ImageId : undefined}
                    type={
                      item.AttachedItems?.value?.length ? 'attachment' : 'item'
                    }
                    count={
                      !!item.Members
                        ? item.Members['@odata.count']
                        : item.AttachedItems?.value.length ?? 0
                    }
                    tags={[
                      item.BusinessType?.Caption.toUpperCase() ?? '',
                      item.TypeDef?.Caption.toUpperCase() ?? '',
                    ].filter((x) => x != '')}
                    onClick={() => onOpen && onOpen(item, 'modal')}
                    onClickItems={() =>
                      onOpenItems && onOpenItems(item, 'modal')
                    }
                    onContextMenu={(e: MouseEvent) => {
                      setContextItem(item);
                      setContextAnchor(e.currentTarget);
                    }}
                  />
                );
              }}
              minWidth={'18rem'}
            />
            <ColumnDirective
              field='StorageObject.FileExtension'
              key='StorageObject.FileExtension'
              headerText='Extension'
            />
            <ColumnDirective
              field='CreatedBy.DisplayName'
              key='CreatedBy.DisplayName'
              headerText='Created By'
            />
            <ColumnDirective
              field='DateCreated'
              key='DateCreated'
              headerText='Date Created'
              type='date'
              format={dateFormat}
            />
            <ColumnDirective
              field='DateModified'
              key='DateModified'
              headerText='Date Modified'
              type='date'
              format={dateFormat}
            />
            <ColumnDirective
              field='DisposalStates'
              key='DisposalStates'
              headerText='Disposal Status'
              template={(item: Item) => <DisposalStatusTemplate item={item} />}
            />
            <ColumnDirective
              field='RetentionClasses'
              key='RetentionClasses'
              headerText='Retention Classes'
              clipMode='Ellipsis'
              template={(item: Item) => (
                <RetentionClassTemplate
                  classifications={item.Classifications?.value ?? []}
                />
              )}
            />
            <ColumnDirective
              field='BusinessType'
              key='BusinessType'
              headerText='Business Type'
              template={(item: Item) => {
                return (
                  (item.BusinessType?.Caption ?? '') !== '' && (
                    <BusinessTypeTemplate
                      title={item.BusinessType?.Caption ?? ''}
                      imageId={
                        !!item.BusinessType
                          ? item.BusinessType._ImageId
                          : undefined
                      }
                      tags={[
                        item.BusinessType?.Caption.toUpperCase() ?? '',
                        item.TypeDef?.Caption.toUpperCase() ?? '',
                      ].filter((x) => x !== '')}
                    />
                  )
                );
              }}
            />
            <ColumnDirective
              field='Status'
              key='Status'
              headerText='Status'
              template={(item: Item) => {
                return <ItemManagementStatusChip status={item.Status} />;
              }}
            />
            <ColumnDirective
              field='RepositoryURL'
              key='RepositoryURL'
              headerText='Repository URL'
              template={(item: Item) => (
                <CommentTemplate
                  comment={item.RepositoryUrl}
                  isURL={true}
                  onClick={onOpenURL}
                  numberOfLines={3}
                  //open context menu - future use
                />
              )}
            />
          </ColumnsDirective>
        </TreeGridComponent>
        <DetailDialog
          title='Comment'
          open={!!comment}
          onClose={() => setComment(null)}
        >
          {comment as string}
        </DetailDialog>

        <OpenInContextMenu
          anchor={contextAnchor!}
          options={['new tab', 'new window']}
          onClose={() => {
            setContextAnchor(null);
          }}
          onOpen={(target) => {
            setContextAnchor(null);
            onOpen && contextItem && onOpen(contextItem, target);
          }}
        />
      </Box>
    );
  }
);
export default ItemsGrid;
