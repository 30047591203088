import { useEffect, useState } from 'react';
import { Item } from '../types';
import {
  useGetItemDetailsFullDetailsQuery,
  useGetItemDetailsMemberOfQuery,
  useGetItemDetailsApplicableClassificationsQuery,
  useGetCollectionItemsCountQuery,
  useGetPropLinksQuery,
} from '../services';

const useFetchPropLinks = (itemType: string | null, itemId: string | null) => {

  const { data: propLinksData, isLoading: isFetchingPropLinks } = useGetPropLinksQuery({
    itemType: itemType,
    itemId: itemId,
  });

  return { propLinksData, isFetchingPropLinks };
};

export const useItemDetails = ({ id }: { id?: string }) => {
  const { data: itemDetailsWithFullDetails, isLoading: isFetchingFullDetails } =
    useGetItemDetailsFullDetailsQuery(
      {
        itemId: id,
      },
      { skip: !id }
    );

  const [itemType, setItemType] = useState<string | null>(null);
  const [itemId, setItemId] = useState<string | null>(null);

  useEffect(() => {
    if (itemDetailsWithFullDetails) {
      if (itemDetailsWithFullDetails.TypeDefId && itemDetailsWithFullDetails.ID) {
        setItemType(itemDetailsWithFullDetails.TypeDefId);
        setItemId(itemDetailsWithFullDetails.ID);
      }
    }
  }, [itemDetailsWithFullDetails]);

  const { propLinksData, isFetchingPropLinks } = useFetchPropLinks(itemType, itemId);

  const { data: itemDetailsWithMemberOf, isLoading: isFetchingMemberOf } =
    useGetItemDetailsMemberOfQuery(
      {
        itemId: id,
      },
      { skip: !id }
    );

  const { data: itemCollectionCount, isLoading: isFetchingItemCount } =
    useGetCollectionItemsCountQuery({
      collectionId: id,
    });

  const {
    data: itemDetailsWithApplicableClassifications,
    isLoading: isFetchingApplicableClassifications,
  } = useGetItemDetailsApplicableClassificationsQuery(
    {
      itemId: id,
    },
    { skip: !id }
  );

  const itemDetails = {
    ...itemDetailsWithFullDetails,
    ...itemDetailsWithMemberOf,
    ...itemDetailsWithApplicableClassifications,
  } as Item;

  return {
    itemDetails,
    isFetching:
      isFetchingFullDetails ||
      isFetchingMemberOf ||
      isFetchingApplicableClassifications ||
      isFetchingItemCount ||
      isFetchingPropLinks,
    itemCollectionCount,
    propertyDetails: itemDetailsWithFullDetails,
    propLinksData
  };
};
