import { Approver } from '../../../types';
import { Button } from '../../button';

type ActionButtonProps = {
  onAction: (comment: string, selectedApprovers: Approver[]) => void;
  selectedApprovers: Approver[];
  setIsValidCondition: (selectedApprovers: Approver[]) => boolean;
  setValidity: (value: boolean) => void;
  label: string;
  comment?: string;
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  onAction,
  comment,
  selectedApprovers,
  setIsValidCondition,
  setValidity,
  label,
}) => {
  const handleClick = () => {
    if (setIsValidCondition(selectedApprovers)) {
      onAction(comment, selectedApprovers);
    } else {
      setValidity(false);
    }
  };

  return (
    <Button variant='contained' onClick={handleClick}>
      {label}
    </Button>
  );
};
