import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import { ReactNode } from 'react';
import { Box } from '../Box';
import { MultilineTypography } from '../MultilineTypography';
import { CloseButton, TextIconButton } from '../button';

export type DetailDialogProps = {
  title?: string;
  icon?: ReactNode;
  children?: string | ReactNode;
  open?: boolean;
  height?: number;
  width?: number;
  onClose?: () => void;
  onAction?: () => void;
};

export const DetailDialog = ({
  title,
  open,
  icon,
  children,
  height,
  width,
  onClose,
  onAction,
}: DetailDialogProps) => (
  <Dialog
    open={open}
    PaperProps={{
      sx: {
        minWidth: '30rem',
        maxWidth: '54rem',
        minHeight: '20rem',
        maxHeight: '40rem',
        ...(height && { height: `${height}rem` }),
        ...(width && { width: `${width}rem` }),
      },
    }}
  >
    <DialogTitle
      sx={{
        background: 'none',
        justifyContent: 'space-between',
        marginBottom: 0,
        paddingLeft: '1.5rem',
      }}
    >
      {(icon || title) && (
        <TextIconButton
          startIcon={icon}
          onClick={onAction && (() => onAction())}
          text={title}
          textVariant='h2'
          color='info'
        />
      )}
      <Box background='none'></Box>
      <CloseButton size='small' onClick={onClose && (() => onClose())} />
    </DialogTitle>
    <Divider sx={{ margin: '0 2rem 1rem 2rem' }} />
    <DialogContent>
      {typeof children === 'string' ? (
        <MultilineTypography variant='body1'>{children}</MultilineTypography>
      ) : (
        children
      )}
    </DialogContent>
  </Dialog>
);

export default DetailDialog;
